import { applyTransparency, currencyFormat, generateBackgroundColor, message, sensurarEmail, sensurarString } from "../../utils/Funciones.js";
import { IoCart, IoDesktopSharp, IoFlashSharp, IoServer } from "react-icons/io5";
import { useEffect, useState, useMemo, useRef } from "react";
import Spinner from "../Spinner";
import { FcMultipleSmartphones } from "react-icons/fc";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { useDispatch, useSelector } from "react-redux";
import draftToHtml from "draftjs-to-html";
import parse from 'html-react-parser';
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { FaClipboardCheck, FaExpeditedssl, FaMobileScreen, FaQuestion, FaScreenpal, FaUser, FaUserTag } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/es';
import Modal from "../../components/modal/Modal"
import { Button, Checkbox, IconButton, Tooltip } from "@mui/material";
import { SET_TO_DEFAULT_RESPONSE, create } from "../../store/features/slices/ventaSlice";
import { SET_TO_DEFAULT_RESPONSE as SET_TO_DEFAULT_RESPONSE_SEARCH } from "../../store/features/slices/searchSlice";
import { MuiTelInput } from "mui-tel-input";
import { SET_CASH_USER } from "../../store/features/slices/userSlice.jsx";
import { MdAutorenew, MdSecurity, MdSyncDisabled } from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { UPDATE_LAST10_OF_CATEGORY, UPDATE_SUBCATEGORY } from "../../store/features/slices/categorySlice.jsx";
import Socket, { authSocket, logoutSocket } from '../../utils/Socket';
import CardVip from "../CardVip.jsx";
import { RiShoppingBag4Fill, RiVipCrownFill } from "react-icons/ri";
import { useTranslation } from 'react-i18next';

const CategoriesStreaming = ({ socket, comprarVip }) => {
  const { t } = useTranslation();
  const { userToken, user, userInfo } = useSelector((state) => state.userReducer);
  const { isMutation: isMutationSearch } = useSelector((state) => state.searchReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [viewterm, SetViewterm] = useState(false)
  const [editorState2, setEditorState2] = useState(EditorState.createEmpty());
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const {
    categories,
    subcategories,
    isMutation
  } = useSelector((state) => state.categoryReducer);

  const {
    isMutation: isMutationVenta
  } = useSelector((state) => state.ventaReducer);

  var htmlData2 = useMemo(
    () => draftToHtml(convertToRaw(editorState2.getCurrentContent())),
    [editorState2]
  );

  var htmlData = useMemo(
    () => draftToHtml(convertToRaw(editorState.getCurrentContent())),
    [editorState]
  );

  const [optionsStreaming, setOptionsStreaming] = useState({ open: false, categoryId: "", name: "", type: "", logo: "", description: "" })
  const [purchase, SetPurchase] = useState({
    idVenta: "",
    userId: "",
    categoryId: "",
    subcategoryId: "",
    maxscreems: 0,
    imglogo: "",
    name: "",
    discountuser: 0,
    tipoentrega: false,
    open: false,
    selectedmonths: 0,
    totalamount: 0,
    actionTypeAdmin: false,
    location: "",
    minmonths: 1,
    policy: "",
    acept_terms: false,
    stockcuentasBoolean: false,
    stockperfilesBoolean: false,
    type: "",
    isemailrequired: false,
    isrenovable: false,
    autorenovable: false,
    sendWhatsappTouser: false,
    contactoInfo: {
      email: "",
      countrycode: "",
      celular: "",
      alias: ""
    }
  })

  const onClosePurcahse = () => {
    SetPurchase({
      ...purchase,
      idVenta: "",
      userId: "",
      categoryId: "",
      subcategoryId: "",
      maxscreems: 0,
      imglogo: "",
      name: "",
      discountuser: 0,
      tipoentrega: false,
      open: false,
      selectedmonths: 0,
      totalamount: 0,
      actionTypeAdmin: false,
      location: "",
      minmonths: 1,
      policy: "",
      acept_terms: false,
      stockcuentasBoolean: false,
      stockperfilesBoolean: false,
      type: "",
      isemailrequired: false,
      isrenovable: false,
      autorenovable: false,
      sendWhatsappTouser: false,
      contactoInfo: {
        email: "",
        countrycode: "",
        celular: "",
        alias: ""
      }
    })
  }

  const openOptions = (category) => {
    setOptionsStreaming({ ...optionsStreaming, open: !optionsStreaming.open, name: category.name, categoryId: category._id, sublogo: category.sublogo, description: category.description })
    const rawContent = category.description;
    const contentState = convertFromRaw(JSON.parse(rawContent));
    const editorState = EditorState.createWithContent(contentState);
    setEditorState2(editorState)
  }

  const open = (subcat) => {

    if (userToken) {

      var isVip = false
      var discountvip = 0
      if (userInfo?.vip?.state === 'ACTIVE' && new Date(userInfo?.vip?.expirationdate) > new Date()) {
        isVip = true
        discountvip = subcat.discountvip
      }

      if (Number(subcat.minmonths) > 0) {
        if (userInfo?.reseller) {
          if (subcat.cuentacompleta) {
            SetPurchase({
              ...purchase,
              userId: user?.id,
              categoryId: subcat.category?._id,
              subcategoryId: subcat._id,
              maxscreems: subcat.maximoperfiles,
              imglogo: subcat.logo,
              name: subcat.name,
              discountuser: subcat.discountuser,
              tipoentrega: subcat.tipoentrega,
              open: true,
              selectedscreems: "",
              selectedmonths: Number(subcat.minmonths),
              totalamount: subcat.costoreseller - discountvip,
              location: subcat.location,
              minmonths: subcat.minmonths,
              acept_terms: false,
              type: subcat.cuentacompleta,
              isrenovable: subcat.renovable,
              isemailrequired: subcat.requiredemail
            })
          } else {
            SetPurchase({
              ...purchase,
              userId: user?.id,
              categoryId: subcat.category?._id,
              subcategoryId: subcat._id,
              maxscreems: subcat.maximoperfiles,
              imglogo: subcat.logo,
              name: subcat.name,
              discountuser: subcat.discountuser,
              tipoentrega: subcat.tipoentrega,
              open: true,
              selectedscreems: "",
              selectedmonths: Number(subcat.minmonths),
              totalamount: subcat.costoreseller - discountvip,
              location: subcat.location,
              minmonths: subcat.minmonths,
              acept_terms: false,
              type: subcat.cuentacompleta,
              isrenovable: subcat.renovable,
              isemailrequired: subcat.requiredemail
            })
          }
        } else {
          if (subcat.cuentacompleta) {
            SetPurchase({
              ...purchase,
              userId: user?.id,
              categoryId: subcat.category?._id,
              subcategoryId: subcat._id,
              maxscreems: subcat.maximoperfiles,
              imglogo: subcat.logo,
              name: subcat.name,
              discountuser: subcat.discountuser,
              tipoentrega: subcat.tipoentrega,
              open: true,
              selectedscreems: "",
              selectedmonths: Number(subcat.minmonths),
              totalamount: (subcat.costouser - (subcat.costouser * subcat.discountuser) / 100) - discountvip,
              location: subcat.location,
              minmonths: subcat.minmonths,
              acept_terms: false,
              type: subcat.cuentacompleta,
              isrenovable: subcat.renovable,
              isemailrequired: subcat.requiredemail
            })
          } else {
            SetPurchase({
              ...purchase,
              userId: user?.id,
              categoryId: subcat.category?._id,
              subcategoryId: subcat._id,
              maxscreems: subcat.maximoperfiles,
              imglogo: subcat.logo,
              name: subcat.name,
              discountuser: subcat.discountuser,
              tipoentrega: subcat.tipoentrega,
              open: true,
              selectedscreems: "",
              selectedmonths: Number(subcat.minmonths),
              totalamount: (subcat.costouser - (subcat.costouser * subcat.discountuser) / 100) - discountvip,
              location: subcat.location,
              minmonths: subcat.minmonths,
              acept_terms: false,
              type: subcat.cuentacompleta,
              isrenovable: subcat.renovable,
              isemailrequired: subcat.requiredemail
            })
          }
        }

        const rawContent = subcat.policy;
        const contentState = convertFromRaw(JSON.parse(rawContent));
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState)
        setOptionsStreaming({ ...optionsStreaming, open: false, name: "", categoryId: "", sublogo: "" })
      } else {
        //SetPurchase({ ...purchase, selectedmonths: months })
      }
    } else {
      navigate('/login');
    }
  }

  const PayPurchase = (e) => {
    e.preventDefault()
    if (!userToken) {
      navigate('/login');
    } else if (purchase.isemailrequired && purchase.contactoInfo.email.trim().length < 5) {
      message(t('emailrequired'), 'error', 4)
    } else if (userToken && userInfo?.reseller && purchase.sendWhatsappTouser && (purchase.contactoInfo.celular.trim().length < 5 || purchase.contactoInfo.alias.trim().length < 2)) {
      message(t('phonerequired'), 'error', 4)
    } else if (!purchase.acept_terms) {
      message(t('termsaccept'), 'error', 4)
    } else if (purchase.selectedmonths < 1) {
      message(t('selectmonths'), 'error', 4)
    } else {
      dispatch(create({ ...purchase, htmlData: htmlData }))
    }
  }

  useEffect(() => {
    if (isMutationVenta.success) {
      socket.emit('testeo', 'nuevo mensaje')
      message(isMutationVenta.message, 'success', 5)
      dispatch(SET_CASH_USER({ type: 'DECREASE', amount: isMutationVenta.extra.amount }))
      if (isMutationVenta.extra.newDataSubcategory.tipoentrega) {
        Socket.emit('uSubCategoryWhitCategory', { newData: isMutationVenta.extra.newDataSubcategory, newHistory: isMutationVenta.extra.newHistory })
        dispatch(UPDATE_SUBCATEGORY({ newData: isMutationVenta.extra.newDataSubcategory }))
      }
      dispatch(UPDATE_LAST10_OF_CATEGORY(isMutationVenta.extra.newHistory))
      dispatch(SET_TO_DEFAULT_RESPONSE())
      navigate('/manage-account/userorders');
    }
  }, [dispatch, isMutationVenta.success])

  useEffect(() => {
    if (isMutationSearch.open) {
      if (isMutationSearch.data.category?.type === 'Streaming') {
        open(isMutationSearch.data)
        dispatch(SET_TO_DEFAULT_RESPONSE_SEARCH())
      }
    }
  }, [isMutationSearch.open])


  var settings = {
    centerMode: true,
    infinite: true,
    centerPadding: "8px",
    slidesToShow: 3,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    nextArrow: <></>,
    prevArrow: <></>
  };

  var settings2 = {
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    nextArrow: <></>,
    prevArrow: <></>
  };

  return (

    <>
      <Modal
        open={purchase.open}
        onClose={onClosePurcahse}
        headerTitle={`Comprar ${purchase.name}`}
        size="base"
      >
        <div className="flex flex-wrap">
          <div className="w-full text-brand-100">
            <div className="w-full flex items-center border-b-brand-300 dark:border-b-brand-800 mb-3">
              <label className="text-lg text-brand-900 dark:text-brand-200">{t('orderdetails')}</label>
            </div>
            <div className="w-full p-5 text-brand-100 bg-brand-200 dark:bg-brand-800">
              <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center">
                  <div className="border-2 border-brand-300 dark:border-brand-700 bg-brand-50 dark:bg-brand-950 w-20 h-20 flex items-center justify-center">
                    <LazyLoadImage
                      className="object-cover w-16 h-16 z-30 rounded-full p-2"
                      alt={`No image category`}
                      effect="opacity"
                      src={`/images/locations/${purchase.location}.svg`}
                    />
                  </div>
                  <div className="pl-3 text-brand-900 dark:text-brand-200">{purchase.location}</div>
                </div>

                <div className="flex items-center">
                  <div className="border-2 border-brand-300 dark:border-brand-700 bg-brand-50 dark:bg-brand-950 w-20 h-20 flex items-center justify-center">
                    <FcMultipleSmartphones size={45} />
                  </div>
                  <div className="pl-3 text-brand-900 dark:text-brand-200">Digital</div>
                </div>
              </div>
            </div>
          </div>

          <form className="w-full" onSubmit={PayPurchase}>
            <div className="w-full">
              <div className="w-full flex items-center border-b-brand-300 dark:border-b-brand-800 mt-3">
                <label className="text-lg text-brand-900 dark:text-brand-200">{t('howmuchmonths')}</label>
              </div>
              <div className="mt-3 grid grid-cols-1 md:grid-cols-2 gap-4 w-full bg-brand-200 dark:bg-brand-800 p-5 border-b border-b-brand-300 dark:border-b-brand-800">
                <div /*onClick={() => handleSelectedMonths(Number(purchase.minmonths))}*/
                  className={`flex items-center h-24 bg-white cursor-pointer rounded-xl ${purchase.selectedmonths === Number(purchase.minmonths) && 'bg-gradient-blue-custom text-white'}`} key={0}>
                  <div className='w-[75px] h-[75px] flex items-center justify-center'>
                    <LazyLoadImage
                      className="z-10 w-[55px] h-[55px] rounded-full flex"
                      alt={`No image timer}`}
                      effect="opacity"
                      src={`/images/logos/timer.webp`}
                    />
                  </div>
                  <div className='grid grid-wrap w-[calc(100%-75)]'>
                    <div className={`text-xl font-bold  ${purchase.selectedmonths === Number(purchase.minmonths) ? 'text-brand-50' : 'text-brand-900'}`}>
                      {purchase.minmonths} {purchase.minmonths > 1 ? t('months') : t('month')} <span className="text-base">({purchase.minmonths * 30} {t('days')})</span>
                    </div>
                    <div className={`text-sm font-bold  ${purchase.selectedmonths === Number(purchase.minmonths) ? 'text-brand-300' : 'text-brand-700'}`}>
                      {moment(new Date().setDate(new Date().getDate() + purchase.minmonths * 30)).format("DD/MM/yyyy hh:mm:ss")}
                    </div>
                  </div>
                </div>
              </div>
              {
                purchase.isrenovable &&
                <div>
                  <div className="w-full flex items-center border-b-brand-300 dark:border-b-brand-800">
                    <label className="text-lg text-brand-900 dark:text-brand-200">{t('autorenewservice')}
                      <Tooltip title={
                        <div style={{ display: 'block' }}>
                          <div className='w-full mb-2 text-center font-semibold border-b-[2px] border-brand-600'>{t('autorenewservice')}</div>

                          <div className='text-brand-300 dark:text-brand-300 text-sm font-normal'>{t('autorenewservicedetails')}</div>
                        </div>}

                        PopperProps={{
                          sx: {
                            "& .MuiTooltip-tooltip": {
                              fontSize: 12.5,
                            }
                          }
                        }} arrow><IconButton color='primary' className='w-14 h-14 rounded-full bg-brand-800 flex items-center justify-center ml-10'><FaQuestion size={24} className='' /></IconButton></Tooltip>
                    </label>
                  </div>
                  <div className="w-full bg-brand-200 dark:bg-brand-800 p-5">
                    <label className="text-brand-900 dark:text-brand-200 text-sm">{t('autorenew')} ? <span className="font-semibold">{purchase.autorenovable ? <span className="text-green-500 dark:text-green-400">{t('enabled')}</span> : <span className="text-red-500 dark:text-red-400">{t('disabled')}</span>}</span></label>
                    <div className="flex flex-col justify-between h-8 mt-3 relative">

                      <span onClick={() => SetPurchase({ ...purchase, autorenovable: !purchase.autorenovable })} className={`rounded-full flex bg-brand-600 items-center cursor-pointer w-14 justify-start text-center absolute ${purchase.autorenovable && 'hidden'}`}>
                        <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                      </span>

                      <span onClick={() => SetPurchase({ ...purchase, autorenovable: !purchase.autorenovable })} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-14 text-center justify-end absolute ${!purchase.autorenovable && 'hidden'}`}>
                        <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                      </span>
                    </div>
                  </div>
                </div>
              }

              {purchase.isemailrequired &&
                <div>
                  <div className="w-full flex items-center border-b-brand-300 dark:border-b-brand-800">
                    <label className="text-lg text-brand-900 dark:text-brand-200">{t('emailrequired')}
                      <Tooltip title={
                        <div style={{ display: 'block' }}>
                          <div className='w-full mb-2 text-center font-semibold border-b-[2px] border-brand-600'>{t('emailrequired')}</div>

                          <div className='text-brand-300 text-sm font-normal'>{`${t('dear')} ${userInfo?.reseller ? 'reseller' : t('user')} ${t('emailrequireddetails')}`}</div>
                        </div>}

                        PopperProps={{
                          sx: {
                            "& .MuiTooltip-tooltip": {
                              fontSize: 12.5,
                            }
                          }
                        }} arrow><IconButton color='primary' className='w-14 h-14 rounded-full bg-brand-800 flex items-center justify-center ml-10'><FaQuestion size={24} className='' /></IconButton></Tooltip>
                    </label>
                  </div>
                  <div className="w-full bg-brand-200 dark:bg-brand-800 p-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5 mb-0">
                    <div className="w-full">
                      <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">{t('email')} <span className="text-xs text-red-600 dark:text-red-400">({t('required')})</span></label>
                      <input className="custom-style-input"
                        required
                        autoComplete="off"
                        value={purchase.contactoInfo.email}
                        onChange={(e) => SetPurchase({ ...purchase, contactoInfo: { ...purchase.contactoInfo, email: e.target.value } })}
                        type="text"
                      />
                    </div>
                  </div>
                </div>

              }

              {userInfo?.reseller &&
                <>
                  <div className="w-full flex items-center border-b-brand-300 dark:border-b-brand-800">
                    <label className="text-lg text-brand-900 dark:text-brand-200">{t('sendbywhatsapp')}
                      <Tooltip title={
                        <div style={{ display: 'block' }}>
                          <div className='w-full mb-2 text-center font-semibold border-b-[2px] border-brand-600'>{t('sendorderbywhatsapp')}</div>

                          <div className='text-brand-300 dark:text-brand-300 text-sm font-normal'>{t('orderdetailtoclient')}</div>
                        </div>}

                        PopperProps={{
                          sx: {
                            "& .MuiTooltip-tooltip": {
                              fontSize: 12.5,
                            }
                          }
                        }} arrow><IconButton color='primary' className='w-14 h-14 rounded-full bg-brand-800 flex items-center justify-center ml-10'><FaQuestion size={24} className='' /></IconButton></Tooltip>
                    </label>
                  </div>
                  <div className="w-full bg-brand-200 dark:bg-brand-800 p-5">
                    <label className="text-brand-900 dark:text-brand-200 text-sm">{t('senddetailsbywhatsapp')} ? <span className="font-semibold">{purchase.sendWhatsappTouser ? <span className="text-green-500 dark:text-green-400">{t('enabled')}</span> : <span className="text-red-500 dark:text-red-400">{t('disabled')}</span>}</span></label>
                    <div className="flex flex-col justify-between h-8 mt-3 relative">

                      <span onClick={() => SetPurchase({ ...purchase, sendWhatsappTouser: true })} className={`rounded-full flex bg-brand-600 items-center cursor-pointer w-14 justify-start text-center absolute ${purchase.sendWhatsappTouser && 'hidden'}`}>
                        <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                      </span>

                      <span onClick={() => SetPurchase({ ...purchase, sendWhatsappTouser: false, contactoInfo: { ...purchase.contactoInfo, countrycode: 'PE', celular: '', alias: '' } })} className={`rounded-full bg-green-600 flex items-center cursor-pointer w-14 text-center justify-end absolute ${!purchase.sendWhatsappTouser && 'hidden'}`}>
                        <span className="rounded-full w-8 h-8 shadow-inner bg-white shadow"></span>
                      </span>
                    </div>
                  </div>
                </>
              }

              {purchase.sendWhatsappTouser &&
                <div>
                  <div className="w-full flex items-center border-b-brand-300 dark:border-b-brand-800">
                    <label className="text-lg text-brand-900 dark:text-brand-200">{t('clientinfo')}
                      <Tooltip title={
                        <div style={{ display: 'block' }}>
                          <div className='w-full mb-2 text-center font-semibold border-b-[2px] border-brand-600'>{t('forreseller')}</div>

                          <div className='text-brand-300 dark:text-brand-300 text-sm font-normal'>{t('forresellerdetails')}</div>
                        </div>}

                        PopperProps={{
                          sx: {
                            "& .MuiTooltip-tooltip": {
                              fontSize: 12.5,
                            }
                          }
                        }} arrow><IconButton color='primary' className='w-14 h-14 rounded-full bg-brand-800 flex items-center justify-center ml-10'><FaQuestion size={24} className='' /></IconButton></Tooltip>
                    </label>
                  </div>
                  <div className="w-full bg-brand-200 dark:bg-brand-800 p-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5 mb-5">
                    <div className="w-full">
                      <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">{t('phonenumber')} <span className="text-xs text-red-600 dark:text-red-400">({t('required')})</span></label>
                      <MuiTelInput
                        required={purchase.sendWhatsappTouser}
                        className="custom-style-input"
                        helperText={t('whatsappreal')}
                        inputProps={{ className: "text-brand-900 dark:text-brand-200" }}
                        value={purchase.contactoInfo.celular}
                        defaultCountry={'PE'}
                        onChange={(newValue, info) => SetPurchase({ ...purchase, contactoInfo: { ...purchase.contactoInfo, countrycode: info.countryCode, celular: newValue } })}
                      />
                    </div>
                    <div className="w-full">
                      <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Alias <span className="text-xs text-red-600 dark:text-red-400">({t('required')})</span></label>
                      <input className="custom-style-input"
                        autoComplete="off"
                        required={purchase.sendWhatsappTouser}
                        maxLength={12}
                        value={purchase.contactoInfo.alias}
                        onChange={(e) => SetPurchase({ ...purchase, contactoInfo: { ...purchase.contactoInfo, alias: e.target.value } })}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className="relative mt-8">
              <label className="text-brand-900 dark:text-brand-200">{t('termsandconditions')}</label>
              <div class="flex items-center">
                <Checkbox
                  checked={purchase.acept_terms ? true : false}
                  onClick={() => SetPurchase({ ...purchase, acept_terms: !purchase.acept_terms })}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#00008b' } }}
                />
                {!purchase.acept_terms && <span class="animate-ping absolute inline-flex h-8 w-8 rounded-full bg-blue-500 left-2"></span>}
                <label for="link-checkbox" class="ml-1 text-sm font-medium text-brand-900 dark:text-brand-300">{t('agree1')}
                  <a href="#" onClick={() => SetViewterm(!viewterm)} class="text-blue-600 hover:text-blue-500"> {t('agree2')}</a>.</label>

              </div>
              <Modal
                open={viewterm}
                onClose={() => SetViewterm(false)}
                headerTitle={t('agree3')}
                size="base"
              >
                <div className={`h-auto text-brand-900 dark:text-brand-200 relative transition-all duration-500 ease-in-out`}>
                  {parse(htmlData)}
                </div>
              </Modal>
            </div>

            <div className="w-full mt-5">
              <Button disabled={(purchase.selectedmonths < 1 || !purchase.acept_terms || isMutationVenta.loading) ? true : false}
                sx={{
                  "&.Mui-disabled": {
                    background: !"#525252",
                    color: "#a3a3a3"
                  }
                }}
                type="submit" style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                size='large' variant="contained" color="primary" startIcon={(isMutationVenta.loading) ? <Spinner w={'35px'} h={'35px'} /> : <IoCart size={24} className="mr-2" />}>{isMutationVenta.loading ? t('proccessing') : t('pay') + currencyFormat(purchase.totalamount)} </Button>
            </div>
          </form>
        </div>
      </Modal>


      <Modal
        open={optionsStreaming.open}
        onClose={() => setOptionsStreaming({ ...optionsStreaming, open: !optionsStreaming.open, name: "", categoryId: "", sublogo: "", description: "" })}
        headerTitle={`${optionsStreaming.name}`}
        size="normal"
      >
        <div className="flex flex-wrap relative">
          <div className="w-full md:w-full lg:w-5/12 flex items-center justify-center">
            <div className="w-full h-88 md:w-88 flex items-center justify-center">
              <LazyLoadImage
                className="w-72 h-72 z-10 rounded-[2.9rem] object-cover"
                alt={`No image ${optionsStreaming.name}`}
                effect="opacity"
                src={`/images/logos/${optionsStreaming.sublogo}`}
              />
            </div>
          </div>
          <div className="w-full md:w-full lg:w-7/12 text-brand-900 dark:text-brand-200 mt-5 md:mt-5 lg:mt-0">

            <div className="uppercase text-xl font-bold">Streaming {optionsStreaming.name}</div>
            <div className="w-full flex-wrap mt-20">
              {
                !isMutation.loading && subcategories.filter((fil) => fil.category?._id === optionsStreaming.categoryId && fil.enabled).length > 0 ? subcategories.filter((fil) => fil.category?._id === optionsStreaming.categoryId && fil.enabled).sort((a, b) => (Math.floor(new Date(a.created_at).getTime() / 1000)) - (Math.floor(new Date(b.created_at).getTime() / 1000))).map((subcat) => {
                  var discountvip = 0
                  var isVip = false
                  var stockcuentasBoolean = false
                  var stockperfilesBoolean = false
                  var totalstock = subcat.stock ? subcat.stock : 0
                  if (subcat.cuentacompleta && totalstock > 0) {
                    stockcuentasBoolean = true
                  }

                  if (!subcat.cuentacompleta && totalstock > 0) {
                    stockperfilesBoolean = true
                  }

                  if (userInfo?.vip?.state === 'ACTIVE' && new Date(userInfo?.vip?.expirationdate) > new Date()) {
                    isVip = true
                    discountvip = subcat?.discountvip
                  }

                  return (
                    <div className="group shadow-lg shadow-brand-300 dark:shadow-black relative w-full flex items-center mb-24 p-2 bg-white dark:bg-brand-900 transition-all duration-500">
                      {/*
                        subcat.tipoentrega && subcat.stock === 0 &&
                        <div style={{ fontFamily: 'Outfit, sans-serif' }} className="price-tag absolute z-40 top-0 left-[50%] group-hover:opacity-0 transition-all duration-500 flex justify-center items-center">
                          MOMENTANEAMENTE<br></br>
                          SIN STOCK
                        </div>*/
                      }

                      {
                        isVip ?
                          <div className="absolute -top-12 z-20 left-3 grid justify-center">
                            <CardVip vip={userInfo?.vip} />
                            <div className="text-xs text-center">{t('saved')} {currencyFormat(discountvip)}</div>
                          </div>
                          :
                          <div className="absolute -top-14 z-20 left-3 grid justify-center">
                            <div className="relative bg-gradient-to-t from-lightPrimary/40 dark:from-darkPrimary/40 to-lightPrimary dark:to-darkPrimary inline-block text-transparent bg-clip-text text-2xl font-bold">
                              {t('whitvip')}
                              <RiVipCrownFill className="absolute right-24 -top-3 text-lightPrimary dark:text-darkPrimary rotate-45" />
                            </div>
                            <div className="text-xs text-center flex items-center">{t('saved')} {currencyFormat(subcat?.discountvip)}
                              <span onClick={() => (comprarVip(), setOptionsStreaming({ ...optionsStreaming, open: !optionsStreaming.open, name: "", categoryId: "", sublogo: "", description: "" }))} className="ml-4 flex items-center text-[12px] text-white dark:text-white cursor-pointer p-2 bg-lightError dark:bg-darkError rounded-lg">
                                <RiVipCrownFill className="mr-2 text-lg" /> {t('iwant')}
                              </span>
                            </div>
                          </div>

                      }

                      <div className="absolute bottom-3 right-2 ">
                        <div className="flex flex-wrap text-xs sm:text-sm lg:text-sm text-left uppercase font-bold">
                          {
                            subcat.tipoentrega ?
                              <div className={`${totalstock > 0 ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>{totalstock > 0 ? <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-600 rounded-l-lg flex items-center p-2">{t('stock')}: {subcat.stock}</div> : <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-600 rounded-l-lg flex items-center p-2">{t('wostock')}</div>}</div> : <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-600 rounded-l-lg flex items-center p-2">{t('stock')}: 10</div>
                          }
                        </div>
                      </div>

                      <div className="absolute right-0 -top-7 flex items-center text-sm bg-white dark:bg-brand-900 p-2">
                        <LazyLoadImage
                          className="w-7 h-7 z-10 mr-1"
                          alt={`No image ${subcat.location}`}
                          effect="opacity"
                          src={`/images/locations/${subcat.location}.svg`}
                        />
                        {t('zone')} {subcat.location}
                      </div>

                      <div className="flex flex-wrap w-[calc(100%-125px)] relative">
                        <div className="w-full font-semibold uppercase pt-1 pb-1 whitespace-nowrap">{subcat.name}</div>
                        <div style={{ fontFamily: 'Outfit, sans-serif' }} className="w-full md:w-6/12 pt-1 pb-1 text-lg">
                          {
                            !userInfo?.reseller ?
                              <div className="text-green-600 dark:text-green-500 font-bold">
                                {(subcat.costouser) - (((subcat.costouser) * subcat.discountuser) / 100) === 0 ?
                                  <div class="text-center text-blue-950 dark:text-blue-50 bg-gradient-to-r from-blue-400 dark:from-blue-500 rounded-l-lg flex items-center p-2 uppercase">{t('free')}</div>
                                  :
                                  subcat.discountuser > 0 ?
                                    <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2">{subcat.discountuser > 0 && <span className="mr-3 line-through">{currencyFormat(subcat.costouser)}</span>}{currencyFormat((subcat.costouser) - (((subcat.costouser) * subcat.discountuser) / 100) - discountvip)} </div>
                                    :
                                    <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2">{subcat.discountuser > 0 && <span className="mr-3 line-through">{currencyFormat(subcat.costouser)}</span>}{currencyFormat((subcat.costouser) - (((subcat.costouser) * subcat.discountuser) / 100) - discountvip)}</div>
                                }
                              </div>
                              :
                              <div class="text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2">{currencyFormat(subcat.costoreseller - discountvip)}</div>
                          }
                        </div>
                        <div className="w-full pt-1 pb-1">
                          {
                            subcat.tipoentrega ?
                              <div className="w-full flex flex-wrap gap-2">
                                <div className="w-full flex items-center text-xs">
                                  <div className="bg-green-600 text-white max-w-[25px] min-w-[25px] max-h-[25px] min-h-[25px] rounded-full flex items-center justify-center p-1 mr-2"><IoFlashSharp size={16} className="ml-[1px]" /></div><div className="text-brand-900 dark:text-brand-200 font-semibold">{t('orderauto')}</div>
                                </div>
                                <div className="w-full flex items-center text-xs">
                                  <div className="bg-blue-600 text-white max-w-[25px] min-w-[25px] max-h-[25px] min-h-[25px] rounded-full flex items-center justify-center p-1 mr-2"><MdSecurity size={16} className="ml-[1px]" /></div><div className="text-brand-900 dark:text-brand-200 font-semibold">{t('warranty30')}</div>
                                </div>
                              </div>
                              :
                              <div className="w-full flex flex-wrap gap-2">
                                <div className="w-full flex items-center text-xs">
                                  <div className="bg-amber-600 text-white max-w-[25px] min-w-[25px] max-h-[25px] min-h-[25px] rounded-full flex items-center justify-center p-1 mr-2"><FaClipboardCheck size={16} /></div><div className="text-brand-900 dark:text-brand-200 font-semibold">{t('delivered30')}</div>
                                </div>
                                <div className="w-full flex items-center text-xs">
                                  <div className="bg-blue-600 text-white max-w-[25px] min-w-[25px] max-h-[25px] min-h-[25px] rounded-full flex items-center justify-center p-1 mr-2"><MdSecurity size={16} className="ml-[1px]" /></div><div className="text-brand-900 dark:text-brand-200 font-semibold">{t('warranty30')}</div>
                                </div>
                              </div>
                          }
                        </div>
                        <div className="w-full pt-1 pb-1 text-brand-900 dark:text-brand-200">
                          {
                            subcat.cuentacompleta ?
                              <div className="w-full flex items-center text-xs">
                                <div className="bg-amber-600 text-white max-w-[25px] min-w-[25px] max-h-[25px] min-h-[25px] rounded-full flex items-center justify-center p-1 mr-2"><FaScreenpal size={16} /></div><div className="text-brand-900 dark:text-brand-200 font-semibold">{t('accountcomplete')}</div>
                                {
                                  subcat.renovable ?
                                    <><div className="ml-3 bg-green-600 text-white max-w-[25px] min-w-[25px] max-h-[25px] min-h-[25px] rounded-full flex items-center justify-center p-1 mr-2"><MdAutorenew size={18} /></div><div className="text-brand-900 dark:text-brand-200 font-semibold">{t('renewable')}</div></>
                                    :
                                    <><div className="ml-3 bg-red-600 text-white max-w-[25px] min-w-[25px] max-h-[25px] min-h-[25px] rounded-full flex items-center justify-center p-1 mr-2"><MdSyncDisabled size={18} /></div><div className="text-brand-900 dark:text-brand-200 font-semibold">{t('norenewable')}</div></>
                                }
                              </div>
                              :
                              <div className="w-full flex items-center text-xs">
                                <div className="bg-indigo-600 text-white max-w-[25px] min-w-[25px] max-h-[25px] min-h-[25px] rounded-full flex items-center justify-center p-1 mr-2"><FaMobileScreen size={16} className="ml-[1px]" /></div><div className="text-brand-900 dark:text-brand-200 font-semibold">{t('onescreen')}</div>
                                {
                                  subcat.renovable ?
                                    <><div className="ml-3 bg-green-600 text-white max-w-[25px] min-w-[25px] max-h-[25px] min-h-[25px] rounded-full flex items-center justify-center p-1 mr-2"><MdAutorenew size={18} /></div><div className="text-brand-900 dark:text-brand-200 font-semibold">{t('renewable')}</div></>
                                    :
                                    <><div className="ml-3 bg-red-600 text-white max-w-[25px] min-w-[25px] max-h-[25px] min-h-[25px] rounded-full flex items-center justify-center p-1 mr-2"><MdSyncDisabled size={18} /></div><div className="text-brand-900 dark:text-brand-200 font-semibold">{t('norenewable')}</div></>
                                }
                              </div>
                          }
                        </div>
                      </div>
                      <div className="flex flex-wrap w-[125px]">
                        <div className="w-full md:w-[125px] flex justify-end">
                          <Button
                            disabled={(!stockcuentasBoolean && !stockperfilesBoolean && subcat.tipoentrega && userToken) ? true : false}
                            onClick={() => open(subcat)}
                            sx={{
                              "&.Mui-disabled": {
                                background: "#525252",
                                color: "#a3a3a3"
                              }, fontSize: '12px !important', paddingLeft: '1px !important', paddingRight: '1px !important'
                            }}
                            type="button" style={{ width: 115, height: 50, marginLeft: 'auto', marginRight: 'auto' }}
                            size='large' variant="contained" color="primary" startIcon={<IoCart size={22} />}>
                            {(!stockcuentasBoolean && !stockperfilesBoolean && subcat.tipoentrega && userToken) ? t('wostock') : t('buy')}</Button>
                        </div>
                      </div>
                    </div>
                  )
                }) :
                  <div class="flex items-center p-3 mb-3 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-brand-800 dark:text-yellow-500" role="alert">
                    <svg class="flex-shrink-0 inline w-8 h-8 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                    <span class="sr-only">Info</span>
                    <div>
                      <span class="font-bold uppercase">{t('nodata')}!</span> {t('thecategory')} <b>{optionsStreaming.name}</b> {t('nodataregister')}!
                    </div>
                  </div>
              }
            </div>
          </div>
          <div className="w-full flex flex-wrap mt-8 text-brand-900 dark:text-brand-200">
            <div className="w-full border-b border-brand-300 dark:border-brand-800 flex flex-wrap mb-2">
              <div className="-mb-[1px] bg-brand-50 dark:bg-brand-950 border-t border-l border-r border-brand-300 dark:border-brand-800 p-2 flex items-center text-blue-600 dark:text-blue-300">{t('usemode')}</div>
            </div>
            <div style={{ whiteSpace: 'pre-wrap' }} className="w-full flex flex-wrap">
              <div>{parse(htmlData2)}</div>
            </div>
          </div>
        </div>
      </Modal >

      <div style={{ fontFamily: 'Outfit, sans-serif' }} className="w-full 
       text-brand-900 dark:text-brand-100
        mb-3 mt-4 md:mt0 text-center text-lg md:text-xl pt-5 pb-5 md:pt-14 md:pb-14 contrast-[125%]">
        <div style={{ fontFamily: 'Outfit, sans-serif' }} className="uppercase">{t('services')}</div>
        <div className="text-4xl md:text-6xl bg-gradient-to-t from-brand-800 to-red-500 inline-block text-transparent bg-clip-text">
          STREAMING
        </div>
      </div>
      <div className="flex flex-wrap w-full pb-10">
        <div className="w-full min-h-[22rem] grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-x-3 md:gap-x-8 gap-y-8 md:gap-y-14 mb-4">
          {
            !isMutation.loading && categories.filter(fil => fil.type === 'Streaming' && fil.enabled).map((category, index) => {
              var isVip = false
              var discountvip = 0
              if (userInfo?.vip?.state === 'ACTIVE' && new Date(userInfo?.vip?.expirationdate) > new Date()) {
                isVip = true
                discountvip = subcategories.filter((fil) => fil.category?._id === category._id)[0]?.discountvip
              }
              return (
                <div onClick={() => openOptions(category)} key={index} className="relative shadow-lg shadow-brand-300 dark:shadow-black bg-white dark:bg-brand-950 group w-full min-h-[300px] h-[300px] md:h-[310px] lg:h-[320px] overflow-hidden rounded-xl transition-transform ease-in-out duration-700 md:hover:scale-[115%] cursor-pointer">
                  <div style={{ backgroundColor: `${applyTransparency(category.themecolor, 0.2)}` }} class="absolute w-56 h-48 blur-[90px] -left-1/2 -top-1/2"></div>
                  <div style={{ backgroundColor: `${applyTransparency(category.themecolor, 0.2)}` }} class="absolute w-56 h-48 blur-[90px] -right-1/2 -top-1/2"></div>
                  <div style={{ backgroundColor: `${category.themecolor}` }} class="absolute w-72 h-48 blur-[50px] -right-1/2 -bottom-1/2"></div>
                  <div className="absolute z-30 right-2 top-2 opacity-0 transform translate-y-10 group-hover:opacity-100 group-hover:translate-y-0 transition duration-500 ease-in-out">
                    <LazyLoadImage
                      className="object-cover max-w-[65px] min-w-[65px] max-h-[65px] min-h-[65px] rounded-full z-10 transition-all ease-in-out duration-700 [box-shadow:0px_1px_5px_1px_var(--tw-shadow-color)] shadow-black dark:shadow-black"
                      alt={`No image ${category.name}`}
                      effect="opacity"
                      src={`/images/logos/${category.sublogo}`}
                    />
                  </div>
                  <LazyLoadImage
                    className="h-[calc(100%-6.5rem)] w-[calc(100%-2px)] z-5 absolute ml-[1px]"
                    alt={`No image ${category.name}`}
                    effect="opacity"
                    src={`/images/logos/${category.logo}`}
                  />
                  <div className="absolute h-full w-full top-0">
                    <div class="relative w-full h-[calc(100%-9rem)] z-10">
                      {
                        subcategories.filter((fil) => fil.category?._id === category._id) &&
                        !userInfo?.reseller &&
                        Number(subcategories.filter((fil) => fil.category?._id === category._id && fil.discountuser > 0)[0]?.discountuser) > 0 &&
                        <div className="absolute w-[70px] p-1 text-center top-[2rem] left-0 z-30 price-tag2 group-hover:animate-pulse">
                          <span className="price">-{Number(subcategories.filter((fil) => fil.category?._id === category._id && fil.discountuser > 0 && fil.enabled)[0]?.discountuser)}%</span>
                        </div>
                      }
                    </div>
                    <div className="h-[9rem]">
                      <div style={{ backgroundColor: `${applyTransparency(category.themecolor, 0.1)}` }} className="relative">
                        <div class="relative w-full h-[2rem] z-10 flex items-center justify-center">
                          {
                            subcategories.filter((fil) => fil.category?._id === category._id && fil.enabled)[0]?.enabled &&
                            <div className="text-brand-50 !filter-none text-center font-bold text-[1.6rem] md:text-[1.8rem] lg:text-[2rem] [text-shadow:0px_3px_3px_var(--tw-shadow-color)] shadow-black dark:shadow-black">
                              {!userInfo?.reseller ? currencyFormat((Number(subcategories.filter((fil) => fil.category?._id === category._id && fil.enabled)[0].costouser)) - (((Number(subcategories.filter((fil) => fil.category?._id === category._id && fil.enabled)[0].costouser)) * Number(subcategories.filter((fil) => fil.category?._id === category._id && fil.enabled)[0].discountuser)) / 100) - discountvip) : currencyFormat(Number(subcategories.filter((fil) => fil.category?._id === category._id && fil.enabled)[0].costoreseller) - discountvip)}
                            </div>
                          }
                        </div>
                        <div class="relative w-full h-[2rem] z-10 flex items-center justify-center text-brand-50 text-[0.9rem] md:text-[1rem] lg:text-[1.1rem] uppercase font-semibold [text-shadow:0px_3px_3px_var(--tw-shadow-color)] shadow-black dark:shadow-black">
                          {category.name}
                        </div>
                      </div>
                      <div class="relative w-full h-[4.2rem] z-10 flex items-center justify-center bg-white dark:bg-brand-950">
                        {
                          category.history.length >= 3 &&
                          <div className="flex flex-wrap justify-center w-full">
                            <Slider className="!relative overflow-hidden !rounded-l-full !rounded-r-lg max-w-[95px] min-w-[95px] max-h-[2.5rem] min-h-[2.5rem] z-20" key={index} {...settings}>
                              {
                                category.history?.map((item) => {
                                  return (
                                    <>
                                      {
                                        item?.history?.user?.photo === 'unknowphoto.webp' ?
                                          <div style={{ background: `${generateBackgroundColor(item?.history?.user?.name)}` }} className="uppercase -ml-[8px] relative mr-4 size-[2.5rem] rounded-full flex items-center justify-center text-white text-xl font-normal border-2 border-white dark:border-brand-900 z-10" key={item._id}>
                                            {item?.history?.user?.name.substr(0, 1)}
                                          </div>
                                          :
                                          <div className="-ml-[8px] overflow-hidden z-10 relative mr-4 size-[2.5rem] rounded-full flex items-center justify-center" key={item._id}>
                                            <LazyLoadImage
                                              className="object-cover size-[2.5rem] rounded-full border-2 border-white dark:border-brand-900 z-10"
                                              alt={`No image ${item?.history?.user?.name}`}
                                              effect="opacity"
                                              src={`/images/photos/${item?.history?.user?.photo}`}
                                            />
                                          </div>
                                      }
                                    </>
                                  )
                                })
                              }
                            </Slider>
                            {
                              category.history?.length > 0 &&
                              <div className="-ml-[17px] z-30 relative size-[2.5rem] rounded-full border-2 border-white dark:border-brand-900 z-10 flex items-center justify-center bg-brand-900 dark:bg-brand-50">
                                <FaUser size={22} className="text-brand-50 dark:text-brand-900" />
                              </div>
                            }

                            <Slider className="w-[calc(95%)] z-20 !m-auto h-[20px]" key={category._id} {...settings2}>
                              {
                                category.history?.map((item) => {
                                  return (
                                    <div className="relative text-[9px] font-normal text-center text-black dark:text-white px-1 overflow-hidden" key={item._id}>
                                      {sensurarEmail(item?.history?.user?.email ? item?.history?.user?.email : 'unknow@unknow.com')} {moment(item?.history?.created_at).fromNow()}
                                    </div>)
                                })
                              }
                            </Slider>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </>
  );
};

export default CategoriesStreaming;