import React, { useState } from 'react';
import i18n from '../utils/i18n';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Dropdown from './dropdown';
import { FaCaretDown } from 'react-icons/fa6';

const LanguageSelector = () => {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [viewMenuLang, SetViewMenuLang] = useState(false)

  const OpenMenuLang = () => {
    SetViewMenuLang(!viewMenuLang)
  }

  return (
    <div className="relative z-50">
      <div className="flex items-center rounded-sm max-w-[40px] min-w-[40px] max-h-[45px] min-h-[45px] cursor-pointer">
        {
          viewMenuLang && <div className="bg-opacity-0 fixed inset-0 z-30" onClick={OpenMenuLang}></div>
        }

        <div className="relative flex items-center justify-end md:justify-center max-w-[40px] min-w-[40px] max-h-[45px] min-h-[45px]">
          <Dropdown
            button={
              <div className="grid place-items-center">
                <LazyLoadImage
                  className="object-cover rounded-sm h-4 w-6 md:h-5 md:w-7"
                  alt={'No image'}
                  effect="opacity"
                  src={`/images/flags/4x3/${localStorage.getItem('i18nextLng') === 'en' ? 'US.svg' : localStorage.getItem('i18nextLng') === 'pt' ? 'BR.svg' : 'ES.svg'}`}
                />
                <FaCaretDown className="text-brand-900 dark:text-brand-100"/>
              </div>
            }
            children={
              <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white dark:!bg-brand-900 bg-cover bg-no-repeat shadow-xl shadow-shadow-500 !text-brand-700 dark:!text-white dark:shadow-none">
                <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

                <div className="flex flex-col p-2">
                  <div onClick={() => changeLanguage('es')} className="flex items-center w-full h-14 text-base transition-colors ease-in-out duration-500 hover:bg-lightPrimary/20 dark:hover:darkPrimary/20 active:bg-lightPrimary/40 dark:active:darkPrimary/40">
                    <div className="flex items-center h-14 !text-brand-700 dark:!text-white p-2 mr-2">
                      Español
                    </div>
                  </div>

                  <div onClick={() => changeLanguage('en')} className="flex items-center w-full h-14 text-base transition-colors ease-in-out duration-500 hover:bg-lightPrimary/20 dark:hover:darkPrimary/20 active:bg-lightPrimary/40 dark:active:darkPrimary/40">
                    <div className="flex items-center h-14 !text-brand-700 dark:!text-white p-2 mr-2">
                      English
                    </div>
                  </div>

                  <div onClick={() => changeLanguage('pt')} className="flex items-center w-full h-14 text-base transition-colors ease-in-out duration-500 hover:bg-lightPrimary/20 dark:hover:darkPrimary/20 active:bg-lightPrimary/40 dark:active:darkPrimary/40">
                    <div className="flex items-center h-14 !text-brand-700 dark:!text-white p-2 mr-2">
                      Português
                    </div>
                  </div>
                </div>
              </div>
            }
            classNames={"py-2 top-10 -left-[180px] w-max"}
          />
        </div>

      </div>
    </div>
  );
};

export default LanguageSelector;
