import axios from "axios";
import { verifyToken } from "../../../utils/Funciones";
const API_URL = `https://yekasastreaming.store:8443/api/user/`;

const login = async (formData) => {
    const response = await axios.post(API_URL + 'login', formData);
    return response.data;
};

const register = async (formData) => {
    const response = await axios.post(API_URL + 'register', formData);
    return response.data;
};

const subscribevip = async (formData) => {
    const response = await axios.patch(API_URL + 'subscribevip', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('userToken')
        },
    });
    return response.data;
};

const create = async (formData) => {
    const response = await axios.post(API_URL + 'create', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const get = async (token) => {
    const response = await axios.get(API_URL, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    });
    return response.data;
};

const getInitialData = async () => {
    const response = await axios.get(API_URL);
    return response.data;
};

const getUserInfo = async (id) => {
    const response = await axios.get(API_URL + `userinfo/${id}`, {
        headers: {
            Authorization: 'Bearer ' + (verifyToken('adminToken') || verifyToken('userToken')),
            'Content-Type': 'application/json'
        },
    });
    return response.data;
};

const getConfiguration = async (id) => {
    const response = await axios.get(API_URL + `configuration`);
    return response.data;
};

const getById = async (token) => {
    const response = await axios.get(API_URL, {
        headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
    });
    return response.data;
};

const getByPage = async (query) => {
    const response = await axios.get(API_URL + `usersbypage${query}`, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken'),
            'Content-Type': 'application/json'
        },
    });
    return response.data;
};

const contador = async () => {
    const response = await axios.get(API_URL + `contador`, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const del = async (id) => {
    const response = await axios.delete(API_URL + id , {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken'),
            'Content-Type': 'application/json'
        },
    });
    return response.data;
};

const update = async (formData) => {
    const response = await axios.patch(API_URL + 'updateuser', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const forgotPasswordRequest = async (formData) => {
    const response = await axios.patch(API_URL + 'forgotpasswordrequest', formData);
    return response.data;
};

const updateForgotPasswordRequest = async (formData) => {
    const response = await axios.patch(API_URL + 'updateforgotpasswordrequest', formData);
    return response.data;
};

const updateProfileInfo = async (formData) => {
    const response = await axios.patch(API_URL + 'updateprofileinfo', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('userToken')
        },
    });
    return response.data;
};

const updateConfiguration = async (formData) => {
    const response = await axios.patch(API_URL + 'updateconfiguration', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const lock = async (formData) => {
    const response = await axios.patch(API_URL + 'lock', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const addCash = async (formData) => {
    const response = await axios.patch(API_URL + 'addcash', formData, {
        headers: {
            Authorization: 'Bearer ' + verifyToken('adminToken')
        },
    });
    return response.data;
};

const userService = {
    getInitialData,
    register,
    login,
    create,
    subscribevip,
    get,
    getUserInfo,
    getConfiguration,
    getById,
    getByPage,
    contador,
    update,
    forgotPasswordRequest,
    updateForgotPasswordRequest,
    updateProfileInfo,
    updateConfiguration,
    lock,
    addCash,
    del
};

export default userService;
