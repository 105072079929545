import CategoriesStreaming from "../../components/home/CategoriesStreaming";
import Nav from "../../components/home/Nav";
import SliderBanner from "../../components/home/SliderBanner";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import moment from 'moment';
import 'moment/locale/es';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import io from "socket.io-client"
import CategoriesSocial from "../../components/home/CategoriesSocial";
import "swiper/css/effect-fade"
import CategoriesGiftCard from "../../components/home/CategoriesGiftCard";
import LastVentas from "../../components/home/LastVentas";
import SubscripcionVip from "../../components/home/SubscripcionVip";
import { SET_TO_DEFAULT_RESPONSE } from "../../store/features/slices/searchSlice";
import Modal from "../../components/modal/Modal";
import { useTranslation } from 'react-i18next';

const socket = io('https://yekasastreaming.store:8443', {
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  randomizationFactor: 0.5
})

const Home = ({ }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { configuration } = useSelector((state) => state.userReducer);
  const { vip } = useSelector((state) => state.searchReducer);

  const sectionStreamingRef = useRef();
  const sectionGiftCardRef = useRef();
  const sectionSocialRef = useRef();
  const sectionHistoryRef = useRef();
  const sectionVipRef = useRef();

  const handleFocusVip = () => {
    if (sectionVipRef.current) {
      sectionVipRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  useEffect(() => {
    if (vip.open) {
      if (sectionVipRef.current) {
        sectionVipRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      dispatch(SET_TO_DEFAULT_RESPONSE())
    }
  }, [vip.open])

  return (
    <div className="w-full relative">
      <Nav />
      <div className="w-full max-w-[1360px] min-w-[300px] m-auto mb-10 md:pt-4 md:pl-4 md:pr-4 md:pb-10 p-4 relative">
        <SliderBanner />
        <div className="flex flex-wrap">
          <div ref={sectionStreamingRef} className="w-full text-brand-900 dark:text-brand-200">
            <CategoriesStreaming
              openFromSearchFinal
              socket={socket}
              comprarVip={handleFocusVip}
            />
          </div>

          <div ref={sectionGiftCardRef} className="w-full text-brand-900 dark:text-brand-200">
            <CategoriesGiftCard
              socket={socket}
              comprarVip={handleFocusVip}
            />
          </div>

          <div ref={sectionSocialRef} className="w-full text-brand-900 dark:text-brand-200">
            <CategoriesSocial
              socket={socket}
              comprarVip={handleFocusVip}
            />
          </div>

          <div ref={sectionVipRef} className="w-full text-brand-900 dark:text-brand-200">
            <SubscripcionVip
              socket={socket}
            />
          </div>

          {/* <div ref={sectionHistoryRef} className="w-full text-brand-900 dark:text-brand-200">
            <LastVentas
              socket={socket}
            />
          </div>*/}

        </div>
      </div>
      <footer className="w-full p-4 md:p-4 lg:p-0">
        <div class="w-full bg-brand-200 dark:bg-brand-900 text-brand-900 dark:text-brand-200 py-3 px-3 md:px-3 lg:px-0 text-brand-400 text-sm">
          <div class="container mx-auto w-full max-w-[1360px] min-w-[300px]">
            <div class="w-full flex flex-wrap">
              <div class="my-4 w-full md:w-6/12 lg:w-6/12">
                <div className="w-full md:w-10/12 lg:w-6/12">
                  <Link to="/" className="">
                    <img className="object-cover w-40 invert dark:filter-none" src={`/images/logos/${configuration?.logo}`} alt={`No image logo`}></img>
                  </Link>
                  <p class="text-justify mt-5">
                    {configuration?.name} {t('pagedescription')}
                  </p>
                </div>
              </div>
              <div class="w-full flex flex-wrap my-4 w-full md:w-6/12 lg:w-6/12">
                <div className="w-full md:w-full lg:w-7/12">
                  <div className="w-full">
                    <h2 class="text-xl pb-4 mb-0 border-b-4 border-blue-600 text-left">{t('methodspay')}</h2>
                  </div>
                  <div className="w-full flex justify-end mt-4">
                    <LazyLoadImage
                      className="w-84 h-10"
                      alt={`No image`}
                      effect="opacity"
                      src={`/images/paymethods/paymethods.webp`}
                    />
                  </div>
                </div>
                <div class="w-full md:w-full lg:w-5/12 flex justify-start md:justify-end">
                  <div className="">
                    <div className="w-full">
                      <h2 class="text-xl pb-4 mb-0 border-b-4 border-blue-600 text-left">{t('contactus')}</h2>
                    </div>
                    <div className="w-full flex justify-end mt-4">
                      <a href={`${configuration?.social?.facebook}`} class="inline-flex items-center justify-center h-8 w-8 border border-brand-100 rounded-full mr-1 hover:text-blue-400 hover:border-blue-400">
                        <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                          <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                        </svg>
                      </a>
                      <a href={`${configuration?.social?.instagram}`} class="inline-flex items-center justify-center h-8 w-8 border border-brand-100 rounded-full mr-1 hover:text-blue-400 hover:border-blue-400">
                        <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                          <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                        </svg>
                      </a>
                      <a href={`${configuration?.social?.tiktok}`} class="inline-flex items-center justify-center h-8 w-8 border border-brand-100 rounded-full mr-1 hover:text-blue-400 hover:border-blue-400">
                        <svg class="w-4 h-4 fill-current" fill="#000000" width="800px" height="800px" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
                          <path d="M232,84v40a7.99977,7.99977,0,0,1-8,8,103.32406,103.32406,0,0,1-48.00049-11.70752L176,156A76,76,0,1,1,86.59766,81.17971,7.99952,7.99952,0,0,1,96,89.05569l-.00049,41.63916a7.99971,7.99971,0,0,1-4.56689,7.22607A20.00272,20.00272,0,1,0,120,156V28a7.99977,7.99977,0,0,1,8-8h40a7.99977,7.99977,0,0,1,8,8,48.05436,48.05436,0,0,0,48,48A7.99977,7.99977,0,0,1,232,84Z" />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-lightPrimary dark:bg-darkPrimary py-4 text-brand-100">
          <div class="container mx-auto w-full max-w-[1360px] min-w-[300px]">
            <div class="-mx-4 flex flex-wrap justify-between">
              <div class="px-4 w-full text-center sm:w-auto sm:text-left">
                Copyright © 2023
                {new Date().getFullYear() > 2020 && (" - " + new Date().getFullYear() + " ")} {configuration?.name}
              </div>
              <div class="px-4 w-full text-center sm:w-auto sm:text-left">
                {t('madeby')}
                <a
                  className="text-decoration-none font-bold ml-2"
                  href="https://wa.link/ab43gw"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  JasonDEV
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div >
  );
};
export default Home;
