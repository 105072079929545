import { useEffect, useState } from "react"
import Sidebar from "./Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { FaCoins, FaMoon, FaSun } from "react-icons/fa6";
import { HiMenuAlt2 } from "react-icons/hi";
import { currencyFormat } from "../../utils/Funciones";
import { LOGOUT } from "../../store/features/slices/userSlice";
import { AiOutlineLogout } from "react-icons/ai";
import { useTranslation } from 'react-i18next';

const AccountList = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userToken, userInfo } = useSelector((state) => state.userReducer);
  const [theme, setTheme] = useState(localStorage.getItem('color-theme') ? localStorage.getItem('color-theme') : 'dark');
  const [side, setSide] = useState('-translate-x-full')

  const closeSidebar = () => {
    setSide('-translate-x-full');
  }

  const openCloseSidebar = () => {
    if (side === "-translate-x-full") {
      setSide("")
    } else {
      setSide("-translate-x-full");
    }
  }

  const changeTheme = () => {
    if (localStorage.getItem('color-theme') === 'light') {
      document.documentElement.classList.add('dark');
      document.documentElement.classList.remove('light');
      localStorage.setItem('color-theme', 'dark');
      setTheme('dark')
    } else {
      document.documentElement.classList.add('light');
      document.documentElement.classList.remove('dark');
      localStorage.setItem('color-theme', 'light');
      setTheme('light')
    }
  }

  const onLogout = () => {
    if (userToken) {
      dispatch(LOGOUT('userToken'))
      navigate('/');
    }
  }

  return (
    <>
      <Sidebar side={side} closeSidebar={closeSidebar} />
      <section className="sticky z-30 ml-0 md:ml-0 lg:ml-72 bg-brand-200 dark:bg-brand-950 min-h-screen px-3 md:px-3 lg:px-16 overflow-hidden">
        <div className="fixed lg:ml-[.25rem] mt-3 z-40 top-0 h-24 w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] bg-lightPrimary/5 dark:bg-darkPrimary/5 p-2 backdrop-blur-lg flex items-center justify-center">
          <button onClick={openCloseSidebar} data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button"
            className="absolute left-0 top-0 block md:block lg:hidden">
            <span class="sr-only">Open sidebar</span>
            <HiMenuAlt2 size={32} className="text-brand-900 dark:text-brand-50" />
          </button>
          <div className="h-24 flex items-center justify-center">
            <div className="flex items-center w-24 h-24 justify-center">
              <Link to="/">
                <button className="w-20 flex flex-wrap justify-center text-brand-900 dark:text-brand-50 gap-2">
                  <FaHome size={32} className="cursor-pointer text-brand-900 dark:text-brand-50" />
                  {t('home')}
                </button>
              </Link>
            </div>
            <div className="w-24 h-24 flex items-center justify-center">
              {
                theme === 'dark' ?
                  <button onClick={changeTheme} className="w-16 flex flex-wrap justify-center text-brand-900 dark:text-brand-50 gap-2">
                    <FaMoon size={32} className="cursor-pointer text-brand-900 dark:text-brand-50" />
                    {t('dark')}
                  </button>
                  :
                  <button onClick={changeTheme} className="w-16 flex flex-wrap justify-center text-brand-900 dark:text-brand-50 gap-2">
                    <FaSun size={32} className="cursor-pointer text-brand-900 dark:text-brand-50" />
                    {t('light')}
                  </button>
              }
            </div>
            <div className="flex items-center w-24 h-24 justify-center">
              <div className="w-24 flex flex-wrap justify-center text-brand-900 dark:text-brand-50 gap-2">
                <FaCoins size={32} className="cursor-pointer text-brand-900 dark:text-brand-50" />
                <div className="flex items-center w-full">{currencyFormat(userInfo.credits_purchased - userInfo.credits_used)}</div>
              </div>
            </div>
          </div>
          <div className="absolute right-2">
            <button onClick={onLogout} className="w-16 flex flex-wrap justify-center text-brand-900 dark:text-brand-50 gap-2">
              <AiOutlineLogout size={32} className="cursor-pointer text-red-600 dark:text-red-500" />
              {t('logout')}
            </button>
          </div>
        </div>
        <div className="relative bg-brand-200 dark:bg-brand-950 py-5 rounded-xl mt-14 lg:px-1">
          {children}
        </div>
      </section>
    </>
  )
}
export default AccountList;