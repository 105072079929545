import { currencyFormat, generateBackgroundColor, message } from "../../utils/Funciones.js";
import { IoCash, IoEye, IoEyeOff, IoFingerPrint, IoLocation, IoLogIn, IoMenu, IoNotifications, IoPerson, IoPersonAddSharp } from "react-icons/io5";
import { Fragment, useEffect, useState } from "react";
import { Avatar, Box, Button, CircularProgress, IconButton, MenuItem, TextField, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineLogout } from "react-icons/ai";
import { FaArrowLeft, FaCoins, FaMoon, FaPhone, FaRegistered, FaSearch, FaShip, FaSun, FaUser } from "react-icons/fa";
import { HiMenuAlt2 } from "react-icons/hi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import { MdClose, MdLogin, MdMail, MdMenu, MdPhoneIphone, MdShoppingBag, MdVerified } from "react-icons/md";
import Spinner from "../Spinner";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { Transition } from '@headlessui/react';
import { LOGOUT, SET_TO_DEFAULT_RESPONSE, login } from "../../store/features/slices/userSlice.jsx";
import ModalSearch from "../ModalSearch";
import Dropdown from "../dropdown/index.jsx";
import { useForm } from "../../hooks/Form.js";
import SocialLinks from "../social/index.jsx";
import { RiVipCrownFill } from "react-icons/ri";
import { OPEN_SECTION_VIP } from "../../store/features/slices/searchSlice.jsx";
import CountUp from 'react-countup';
import LanguageSelector from "../LanguageSelector.js";
import { useTranslation } from 'react-i18next';
import { CURRENCY } from '../../utils/Constants';

const Nav = ({ openCloseSidebar }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [viewMenu, SetViewMenu] = useState(false)
  const [CurrentNumber, SetCurrentNumber] = useState(0)
  const [searchModalOpen, setSearchModalOpen] = useState(false)
  const [theme, setTheme] = useState(localStorage.getItem('color-theme') ? localStorage.getItem('color-theme') : 'dark');
  const { userToken, adminToken, user, isMutation, userInfo, configuration } = useSelector((state) => state.userReducer);
  const [VerPasswords, SetVerPasswords] = useState({ password: false });
  const { state, onChange } = useForm({ email: '', password: '' })

  const onSubmit = e => {
    e.preventDefault();
    state.adminlogin = false
    if (userToken || adminToken) {
      message(t('currentsession'), 'error', 5)
    } else {
      if (configuration?.login) {
        dispatch(login(state))
      } else {
        message(t('logindisabled'), 'error', 5)
      }
    }
  }

  const changeTheme = () => {
    if (localStorage.getItem('color-theme') === 'light') {
      document.documentElement.classList.add('dark');
      document.documentElement.classList.remove('light');
      localStorage.setItem('color-theme', 'dark');
      setTheme('dark')
    } else {
      document.documentElement.classList.add('light');
      document.documentElement.classList.remove('dark');
      localStorage.setItem('color-theme', 'light');
      setTheme('light')
    }
  }

  const refreshPage = () => {
    window.location.reload(true);
  };

  useEffect(() => {
    if (userToken) {
      SetCurrentNumber(userInfo.credits_purchased - userInfo.credits_used)
    }
  }, [CurrentNumber])

  const OpenMenu = () => {
    SetViewMenu(!viewMenu)
  }

  const onLogout = () => {
    if (userToken) {
      dispatch(LOGOUT('userToken'))
    }
  }

  const handleClick = (e) => {
    if (location.pathname === '/') {
      // Si estamos en Home, refrescar la página
      window.location.reload();
    }
  }

  useEffect(() => {
    if (isMutation.error) {
      if (isMutation.extra?.error === 'not found') {
        dispatch(SET_TO_DEFAULT_RESPONSE())
        navigate('/register');
      }
    }
  }, [isMutation.error])

  const handleOpenSectionVip = (item) => {
    dispatch(OPEN_SECTION_VIP(item))
  }

  return (
    <>
      <div className={`z-40 flex items-center transtion-all duration-800 w-full h-14 bg-white dark:bg-black text-brand-900 dark:text-brand-100`} bis_skin_checked="1">
        <div className="flex items-center h-14 w-full max-w-[1360px] min-w-[300px] m-auto p-2 md:p-0">
          <div className="w-[calc(100%-80px)] md:w-[calc(100%-80px)] grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-1">
            <div className="w-full flex items-center text-green-600 dark:text-green-400">
              <div className="h-full w-[24px] flex items-center justify-center">
                <MdVerified className="text-[18px] lg:text-[24px]" />
              </div>
              <div className="h-full w-[100%-24px] flex items-center lg:grid">
                <div className="w-full text-xs font-bold">OFFICIAL RETAILER</div>
                <div className="w-full text-[10px] hidden lg:flex">EVERY DIGITAL CODE IS OFFICIALLY SOURCED</div>
              </div>
            </div>

            <div className="w-full flex items-center pl-7 md:pl-0">
              <div className="h-full w-[24px] flex items-center justify-center">
                <MdPhoneIphone className="text-[18px] md:text-[24px]" />
              </div>
              <div className="h-full w-[100%-24px] flex items-center">
                <div className="w-full text-[8px] md:text-xs">{configuration?.social?.whatsapp}</div>
              </div>
            </div>

            <div className="w-full flex items-center">
              <div className="h-full w-[24px] flex items-center justify-center">
                <MdMail className="text-[18px] md:text-[24px]" />
              </div>
              <div className="h-full w-[100%-24px] flex items-center">
                <div className="w-full text-[8px] md:text-xs">{configuration?.social?.email}</div>
              </div>
            </div>

            <div className="w-full flex items-center pl-7 md:pl-0">
              <div className="h-full w-[24px] flex items-center justify-center">
                <IoLocation className="text-[18px] md:text-[24px]" />
              </div>
              <div className="h-full w-[100%-24px] flex items-center">
                <div className="w-full text-[8px] md:text-xs">Lima - Perú</div>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div onClick={() => handleOpenSectionVip()} className="w-[40px] relative -mr-2 md:mr-7 mt-2 text-[12px] md:text-[16px] font-bold text-black dark:text-white hover:cursor-pointer hover:scale-[120%] transition-all ease-in-out duration-700">
              <div className="text-right">VIP</div>
              <RiVipCrownFill className="absolute -right-2 -top-2 md:-right-3 md:-top-3  text-black dark:text-white rotate-45" />
            </div>
            <LanguageSelector />
          </div>
          <div className="lg:block hidden">
            <SocialLinks links={configuration} />
          </div>
        </div>
      </div>
      <nav className={`sticky -top-[2px] lg:py-2 transtion-all duration-800 z-40 w-full bg-lightPrimary dark:bg-darkPrimary flex items-center justify-center`}>
        <div className="flex flex-wrap p-3 md:p-3 lg:p-0 w-full max-w-[1360px] min-w-[300px]">
          <div className={`flex items-center w-full md:w-full ${userToken ? 'lg:w-[calc(100%)]' : 'lg:w-[calc(100%-590px)]'}`}>
            <div className="flex items-center w-[120px] md:w-[200px] lg:w-[200px] h-[50px] md:h-[65px] lg:h-[65px]">
              <div className="flex w-[120px] md:w-[170px] lg:w-[170px] items-center justify-start cursor-pointer">
                {
                  <Link to="/" className="">
                    <LazyLoadImage
                      onClick={handleClick}
                      className="object-contain"
                      alt={'No image logo'}
                      src={`/images/logos/${configuration?.logo}`}
                    />
                  </Link>
                }
              </div>
            </div>
            <div className="relative flex flex-wrap justify-end items-center w-[calc(100%-120px)] md:w-[calc(100%-200px)] lg:w-[calc(100%-200px)]">

              <div onClick={(e) => { e.stopPropagation(); setSearchModalOpen(true) }}
                className={`mr-2 flex items-center justify-center rounded-sm ${userToken ? 'w-10 md:w-14' : 'w-10 md:w-14'} h-14 bg-none shadow-xl shadow-shadow hover:contrast-[170%] group rounded-xl cursor-pointer`}>
                <FaSearch size={20} className="text-white group-hover:scale-[140%] transition-all ease-in-out duration-700" />
              </div>

              <ModalSearch id="search-modal" searchId="search" modalOpen={searchModalOpen} setModalOpen={setSearchModalOpen} />

              <div className={`flex items-center justify-center rounded-sm ${userToken ? 'w-10 md:w-14' : 'w-10 md:w-14'} h-14 bg-none shadow-xl shadow-shadow hover:contrast-[170%] group rounded-xl mr-2 cursor-pointer`}>
                {
                  theme === 'dark' ?
                    <Tooltip title={t('changetolight')} arrow={true}>
                      <div onClick={changeTheme} className={`flex items-center justify-center ${userToken ? 'w-10 md:w-14' : 'w-10 md:w-14'} h-14`}
                        sx={{ background: '#172554' }}>
                        <FaMoon size={20} className="cursor-pointer group-hover:scale-[140%] transition-all ease-in-out duration-700" color="white" />
                      </div>
                    </Tooltip>
                    :
                    <Tooltip title={t('changetodark')} arrow={true}>
                      <div onClick={changeTheme} className={`flex items-center justify-center ${userToken ? 'w-10 md:w-14' : 'w-10 md:w-14'} h-14`}
                        sx={{ background: '#172554' }}>
                        <FaSun size={20} className="cursor-pointer group-hover:scale-[140%] transition-all ease-in-out duration-700" color="yellow" />
                      </div>
                    </Tooltip>
                }
              </div>

              <Tooltip title={t('rechargecredits')} arrow={true}>
                <Link to="/manage-account/recharge" className={`lg:flex items-center justify-center rounded-sm ${userToken ? 'w-10 md:w-14' : 'w-12 md:w-14'} h-14 bg-none shadow-xl shadow-shadow hover:contrast-[170%] group rounded-xl cursor-pointer lg:mr-2 hidden md:flex`}>
                  <FaCoins size={20} className="flex text-white group-hover:scale-[140%] transition-all ease-in-out duration-700" />
                </Link>
              </Tooltip>
              {
                !userToken &&
                <div class="relative w-[100px] block md:block lg:hidden">
                  <Link to="/register">
                    <Button
                      type="button"
                      disabled={isMutation.sloading ? true : false}
                      style={{ width: '100%', height: 38, marginLeft: 'auto', marginRight: 'auto' }}
                      size='large' variant="contained" color="error"
                    >
                      {t('register')}
                    </Button>
                  </Link>
                </div>
              }


              {
                userToken &&
                <>
                  <div className="flex items-center rounded-sm w-28 md:w-28 h-14 cursor-pointer justify-center text-white font-bold text-lg md:text-lg lg:text-lg">

                    <CountUp start={CurrentNumber} end={userInfo.credits_purchased - userInfo.credits_used} duration={2} separator="," decimals={2} prefix={`${CURRENCY}`} />

                  </div>
                  <div className="flex items-center rounded-sm max-w-[45px] min-w-[45px] max-h-[45px] min-h-[45px] cursor-pointer">
                    {
                      viewMenu && <div className="bg-opacity-0 fixed inset-0 z-30" onClick={OpenMenu}></div>
                    }

                    <div className="relative flex items-center max-w-[45px] min-w-[45px] max-h-[45px] min-h-[45px]">
                      <Dropdown
                        button={
                          userInfo?.photo === 'unknowphoto.webp' ?
                            <div style={{ background: `${generateBackgroundColor(userInfo?.name)}` }} className="uppercase relative max-w-[45px] min-w-[45px] max-h-[45px] min-h-[45px] rounded-full flex items-center justify-center text-white text-2xl font-normal cursor-pointer z-10">
                              {userInfo?.name.substr(0, 1)}
                            </div>
                            :
                            <LazyLoadImage
                              className="max-w-[45px] min-w-[45px] max-h-[45px] min-h-[45px] rounded-full object-cover cursor-pointer"
                              alt={'No image'}
                              effect="opacity"
                              src={`/images/photos/${userInfo?.photo}`}
                            />
                        }
                        children={
                          <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white dark:!bg-brand-900 bg-cover bg-no-repeat shadow-xl shadow-shadow-500 !text-brand-700 dark:!text-white dark:shadow-none">
                            <div className="p-2">
                              <div className="flex items-center gap-2">
                                <p className="text-sm font-bold text-brand-700 dark:text-white">
                                  👋 {t('hello')}, {userInfo && userInfo?.name?.split(' ')[0]}
                                </p>{" "}
                              </div>
                            </div>
                            <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

                            <div className="flex flex-col p-2">
                              <Link to="/manage-account/profile" className="flex items-center w-full h-14 text-base transition-colors ease-in-out duration-500 hover:bg-lightPrimary/20 dark:hover:darkPrimary/20 active:bg-lightPrimary/40 dark:active:darkPrimary/40">
                                <div className="flex items-center h-14 !text-brand-700 dark:!text-white p-2 mr-2"><IoPerson size={20} className="mr-2" />{t('myaccount')}</div>
                              </Link>

                              <Link to="/manage-account/userorders" className="flex items-center w-full h-14 text-base transition-colors ease-in-out duration-500 hover:bg-lightPrimary/20 dark:hover:darkPrimary/20 active:bg-lightPrimary/40 dark:active:darkPrimary/40">
                                <div className="flex items-center h-14 !text-brand-700 dark:!text-white p-2 mr-2"><MdShoppingBag size={20} className="mr-2" />{t('myorders')}</div>
                              </Link>

                              <Link to="/manage-account/recharge" className="flex items-center w-full h-14 text-base transition-colors ease-in-out duration-500 hover:bg-lightPrimary/20 dark:hover:darkPrimary/20 active:bg-lightPrimary/40 dark:active:darkPrimary/40">
                                <div className="flex items-center h-14 !text-brand-700 dark:!text-white p-2 mr-2"><FaCoins size={20} className="mr-2" />{t('recharge')}</div>
                              </Link>

                              <div onClick={onLogout} className="flex rounded-b-2xl items-center justify-center h-14 transition-all duration-500 p-2 text-white bg-red-600 hover:bg-red-500 active:bg-red-400"><AiOutlineLogout size={20} className="mr-2" />{t('logout')}</div>
                            </div>
                          </div>
                        }
                        classNames={"py-2 top-10 -left-[180px] w-max"}
                      />
                    </div>

                  </div>
                </>
              }
            </div>
          </div>
          {
            !userToken &&
            <form onSubmit={onSubmit} className="flex items-center w-full md:w-full lg:w-[590px] mt-2 md:mt-2 lg:mt-0">
              <div class="flex flex-wrap gap-3 w-full md:w-full lg:w-[590px]">
                <div class="w-[calc(50%-62px)] md:w-[calc(50%-62px)] lg:w-[calc(50%-117px)] relative flex items-center">
                  <input autoFocus name="email" className="custom-style-input-nav"
                    autoComplete="off"
                    placeholder={t('email')}
                    required
                    value={state.email}
                    onChange={onChange}
                    type="email"
                  />
                </div>
                <div class="w-[calc(50%-61px)] md:w-[calc(50%-61px)] lg:w-[calc(50%-117px)] relative flex items-center">
                  <input name="password" className="custom-style-input-nav"
                    autoComplete="off"
                    placeholder={t('password')}
                    required
                    onChange={onChange}
                    value={state.password}
                    type={VerPasswords.password ? 'text' : 'password'}
                  />
                  <div class="absolute right-0 flex items-center text-sm">
                    {<IconButton color='primary' onClick={() => SetVerPasswords({ ...VerPasswords, ['password']: !VerPasswords.password })}>
                      {VerPasswords.password ? <IoEyeOff className="text-lightPrimary/50" /> : <IoEye className="text-lightPrimary/50" />}
                    </IconButton>}
                  </div>
                </div>
                <div class="relative w-[100px]">
                  <Button
                    type="submit"
                    disabled={isMutation.sloading ? true : false}
                    style={{ width: '100%', height: 38, marginLeft: 'auto', marginRight: 'auto' }}
                    size='large' variant="contained" color="success"
                  >
                    {isMutation.sloading ? t('login...') : t('login')}
                  </Button>
                </div>
                <div class="relative w-[100px] hidden md:hidden lg:block">
                  <Link to="/register">
                    <Button
                      type="button"
                      disabled={isMutation.sloading ? true : false}
                      style={{ width: '100%', height: 38, marginLeft: 'auto', marginRight: 'auto' }}
                      size='large' variant="contained" color="error"
                    >
                      {t('register')}
                    </Button>
                  </Link>
                </div>
              </div>
            </form>
          }
        </div>
      </nav >

    </>
  );
};
export default Nav;

