import { IoBan, IoCloseCircle, IoCloseSharp, IoOpenOutline, IoSave, IoSend } from "react-icons/io5";
import Spinner from "../../components/Spinner";
import { currencyFormat, message, setUnixToTimeFormat } from "../../utils/Funciones.js";
import { MdAddReaction, MdAlternateEmail, MdMovieFilter, MdReport, MdScheduleSend } from "react-icons/md";
import { Pagination, PaginationItem, Stack, Button, IconButton, Tooltip, Box, CircularProgress, MenuItem, Select, FormControl } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { FaBusinessTime, FaExchangeAlt, FaPencilAlt, FaShippingFast, FaShopify } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import 'moment/locale/es';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import debounce from 'lodash.debounce';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { SET_TO_DEFAULT_RESPONSE, createMessageReport, emitirOrder, getBypage, updateSocialOrder } from "../../store/features/slices/socialSlice";
import { uriSerialized } from "../../utils/uriSerialized";
import { Input, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { FaClipboard, FaCoins, FaCommentDots, FaEllipsisVertical, FaGavel, FaHandHoldingDollar, FaHourglassStart, FaRegCalendarPlus, FaRegUser, FaSquareCheck, FaUserTag } from "react-icons/fa6";
import Modal from "../../components/modal/Modal";
import Wrapper from './Wrapper';
import SpinnerData from "../../components/SpinnerData";
import CopyToClipboard from "react-copy-to-clipboard";
import SpinnerButtom from "../../components/SpinnerButtom";
import Swal from "sweetalert2";
import Dropdown from "../../components/dropdown";
import { CURRENCY } from '../../utils/Constants';

const SocialOrders = ({ }) => {

  const dispatch = useDispatch();

  const messagesEndRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [pedidosByState, SetPedidosByState] = useState('')
  const [limit, setLimit] = useState(30);
  const [reportorder, setReportOrder] = useState({
    _id: "",
    open: false,
    message: "",
    ventaId: "",
    orderId: "",
    chat: [],
    nameplataform: "",
    plataformlogo: "",
    editmessage: "",
    editmessageid: ""
  })

  const [changeState, setChangeState] = useState({
    open: false,
    _id: "",
    ventaId: "",
    observation: "",
    newState: "",
    amountRefunded: 0,
    amount: 0,
    link: "",
    orderId: ""
  })

  const [curTime, SetCurTime] = useState(null)


  const {
    ventasByPage,
    isMutation,
    totalFilter,
    total
  } = useSelector((state) => state.socialReducer);

  const {
    adminToken,
    admin
  } = useSelector((state) => state.userReducer);

  useEffect(() => {
    if (isMutation.success) {
      message(isMutation.message, 'success', 5)
      dispatch(SET_TO_DEFAULT_RESPONSE())
    }

    const queryOptions = {
      page: currentPage,
      limit: limit,
      search: searchTerm.trim(),
    }
    const query = queryOptions ? "?" + uriSerialized(queryOptions) : ""
    dispatch(getBypage(query))
  }, [dispatch, searchTerm.trim(), currentPage, limit, isMutation.success]);

  useEffect(() => {
    if (isMutation.updated) {
      if (isMutation.extra?.emited) {
        message(isMutation.message, 'success', 5)
      }
      if (changeState.open) {
        message(isMutation.message, 'success', 5)
        setChangeState({
          ...changeState,
          open: false,
          _id: "",
          ventaId: "",
          observation: "",
          newState: "",
          amountRefunded: 0,
          amount: 0
        })
      }

      if (reportorder.open) {
        if (isMutation.extra.type === 'NEW_MESSAGE') {
          setReportOrder({ ...reportorder, message: "", chat: isMutation.extra.chat })
          scrollToBottom();
        } else if (isMutation.extra.type === 'DELETE_MESSAGE') {
          setReportOrder({ ...reportorder, chat: isMutation.extra.chat })
        } else if (isMutation.extra.type === 'UPDATE_MESSAGE') {
          setReportOrder({ ...reportorder, editmessageid: "", chat: isMutation.extra.chat })
        }

      }
      dispatch(SET_TO_DEFAULT_RESPONSE())
    }
  }, [isMutation.updated]);

  useEffect(() => {
    setInterval(function () {
      SetCurTime(new Date().toLocaleString());
    }.bind(this), 1000);
  }, [])

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleReportOrder = (_id, orderId, nameplataform, logo, ventaId, chat) => {
    setReportOrder({ ...reportorder, open: true, _id: _id, orderId: orderId, nameplataform: nameplataform, plataformlogo: logo, ventaId: ventaId, chat: chat });
  }

  const handleEmitirPedido = (_id) => {
    alertaConfirmar('EMITIR PEDIDO', 'Estas seguro que quieres emitir este pedido?', 'question', (res_alert) => {
      if (res_alert === true) {
        dispatch(emitirOrder({
          orderId: _id,
          actionTypeAdmin: true
        }))
      }
    })
  }

  const submitUpdateSocialOrder = (e) => {
    e.preventDefault()
    dispatch(updateSocialOrder(changeState))
  }

  const handleSubmitReportOrder = e => {
    e.preventDefault()
    dispatch(createMessageReport({ type: 'NEW_MESSAGE', _id: reportorder._id, message: reportorder.message, userId: adminToken && admin?.id }))
  }

  const handleDeleteMessage = (messageId) => {
    dispatch(createMessageReport({ type: 'DELETE_MESSAGE', _id: reportorder._id, userId: adminToken && admin?.id, messageId: messageId }))
  }

  const handleEditMessage = (messageId) => {
    if (reportorder.editmessage.trim().length < 1) {
      message('mensaje vacio', 'error', 3)
    } else {
      dispatch(createMessageReport({ type: 'UPDATE_MESSAGE', _id: reportorder._id, userId: adminToken && admin?.id, messageId: messageId, editmessage: reportorder.editmessage }))
    }
  }

  const handleChangePage = (e, p) => {
    setCurrentPage(p);
  };

  const changeHandleSearch = (event) => {
    SetPedidosByState('')
    setSearchTerm(event.target.value)
    setCurrentPage(1);
  }

  const debouncedChangeHandler = useMemo(() => debounce(changeHandleSearch, 500), []);

  const onCopy = (text) => {
    message(text, 'success', 2)
  }

  const onChangeSearchTermClear = e => {
    setSearchTerm("");
    setCurrentPage(1)
    document.getElementById("search").value = "";
  };

  const alertaConfirmar = (titulo, texto, icon, callback) => {
    Swal.fire({
      title: titulo,
      html: texto,
      icon: icon,
      showCancelButton: true,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#2C3E50',
      cancelButtonColor: '#CB4335'
    }).then((result) => {
      if (result.isConfirmed) {
        callback(result.isConfirmed)
      }
    })
  }

  return (

    <Wrapper>
      {/* HEADER PAGES */}
      <div className="fixed pl-3 md:pl-3 lg:pl-3 h-8 top-11 z-30 flex items-center w-8/12 text-brand-900 dark:text-brand-100 text-[1.2rem] md:text-[1.8rem] lg:text-[2rem] font-semibold">
        Pedidos servicios SMM
        <div class="flex items-center justify-center px-3 ml-3 text-xl font-medium bg-brand-300 dark:bg-brand-800 text-brand-800 dark:text-brand-200 rounded-full">
          {!isMutation.loading && total}
        </div>
      </div>
      <div className="fixed mt-8 p-4 flex items-center text-brand-900 dark:text-brand-100 uppercase w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] font-semibold bg-white dark:bg-brand-900 backdrop-blur-lg z-20 rounded-b-xl">
        <div className="!w-9/12">
          <InputGroup className="w-full md:w-[450px] md:max-w-[450px]">
            <InputLeftElement pointerEvents="none" className="!mt-[14px] !ml-2">
              <FiSearch size={24} className="text-brand-900 dark:text-brand-100" />
            </InputLeftElement>
            <Input size='lg' autoFocus id="search" variant='flushed' onChange={debouncedChangeHandler} type="text" placeholder={`Buscar pedidos de servicios smm...`} className="flex w-full md:w-[450px] md:max-w-[450px] !h-14 !pl-10 !items-center !justify-center !border-2 rounded-xl !bg-brand-200 
            dark:!bg-brand-950 !text-sm focus:!ring-lightPrimary dark:focus:!ring-darkPrimary focus:!border-lightPrimary dark:focus:!border-darkPrimary 
            !outline-none !border-brand-300 dark:!border-brand-800 !text-brand-900 dark:!text-white !relative !placeholder-brand-500" />
            <InputRightElement className="!mt-[8px] !mr-1">
              <IconButton onClick={onChangeSearchTermClear} className={`mr-2 ${searchTerm.trim().length > 0 ? 'scale-100' : 'scale-0'} transition-all duration-500 text-brand-600 dark:text-brand-300`}>
                <IoCloseSharp size={20} className="text-brand-900 dark:text-brand-100" />
              </IconButton>
            </InputRightElement>
          </InputGroup>
        </div>
      </div>

      <div className="w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] mb-0 mt-[112px] pb-3 pt-5 md:pt-5 lg:pt-10 fixed z-20 bg-white/10 dark:bg-[#00000023] backdrop-blur-lg flex flex-col md:flex-col lg:flex-row items-center">
        <div className="w-full flex items-center justify-center md:justify-end text-brand-800 dark:text-brand-200 mt-0 h-10">
          <div className="hidden md:block">
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(!isMutation.loading && Number(totalFilter) / limit)}
                page={currentPage}
                onChange={handleChangePage}
                variant="text"
                color="primary"
                shape="rounded"
                size="large"
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
                className="bg-white dark:bg-brand-900"
              />
            </Stack>
          </div>
          <div className="block md:hidden">
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(!isMutation.loading && Number(totalFilter) / limit)}
                page={currentPage}
                onChange={handleChangePage}
                variant="text"
                color="primary"
                shape="rounded"
                size="small"
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
                className="bg-white dark:bg-brand-900"
              />
            </Stack>
          </div>
        </div>
      </div>

      <Modal
        open={changeState.open}
        onClose={() => setChangeState({
          ...changeState,
          open: false,
          _id: "",
          ventaId: "",
          observation: "",
          newState: "",
          amountRefunded: 0,
          amount: 0
        })}
        headerTitle={`Editar pedido ${changeState.ventaId}`}
        size="base"
      >
        <div className="w-full">
          <form className="w-full" onSubmit={submitUpdateSocialOrder}>
            <div className="grid grid-cols-1 gap-4">
              <div className="w-full relative">
                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Numero de orden</label>
                <input name="orderId" className="custom-style-input"
                  autoComplete="off"
                  value={changeState.orderId}
                  type="text"
                  onChange={e => setChangeState({ ...changeState, orderId: e.target.value })}
                />
              </div>
              <div className="w-full">
                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Nuevo estado</label>
                <div className="relative w-full">
                  <FormControl fullWidth>
                    <Select
                      required
                      name="newState"
                      className="flex items-center"
                      variant="outlined"
                      value={changeState.newState}
                      onChange={e => setChangeState({ ...changeState, newState: e.target.value, amountRefunded: e.target.value === 'Refunded' ? changeState.amount : 0, observation: "" })}
                    >
                      <MenuItem value={'Completed'} key={'Completed'}><FaSquareCheck size={24} className="mr-2" />Completado</MenuItem>
                      <MenuItem value={'Pending'} key={'Pending'}><FaShippingFast size={24} className="mr-2" />Pendiente</MenuItem>
                      <MenuItem value={'In progress'} key={'In progress'}><FaBusinessTime size={24} className="mr-2" />Procesando</MenuItem>
                      <MenuItem value={'Refunded'} key={'Refunded'}><FaHandHoldingDollar size={24} className="mr-2" />Reembolsado</MenuItem>
                      <MenuItem value={'Partial'} key={'Partial'}><FaHandHoldingDollar size={24} className="mr-2" />Reembolso parcial</MenuItem>
                      <MenuItem value={'Rejected'} key={'Rejected'}><IoBan size={24} className="mr-2" />Rechazado</MenuItem>
                      <MenuItem value={'Canceled'} key={'Canceled'}><IoCloseCircle size={24} className="mr-2" />Cancelado</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              {
                (changeState.newState === 'Refunded' || changeState.newState === 'Partial') &&
                <div className="w-full relative">
                  <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Monto a reembolsar</label>
                  <input name="amountRefunded" className="custom-style-input pl-10"
                    autoComplete="off"
                    disabled={changeState.newState === 'Refunded' ? true : false}
                    required={(changeState.newState === 'Refunded' || changeState.newState === 'Partial') ? true : false}
                    max={changeState.amount}
                    value={changeState.amountRefunded}
                    type="number"
                    step={0.1}
                    onChange={e => setChangeState({ ...changeState, amountRefunded: e.target.value })}
                    onWheel={(e) => e.target.blur()}
                    min={0}
                  />
                  <div class="absolute text-brand-900 dark:text-brand-200 inset-y-4 mt-7 left-0 pl-3 flex items-center text-lg leading-5">
                    {CURRENCY}
                  </div>
                </div>
              }
              {
                (changeState.newState !== 'Refunded' && changeState.newState !== 'Partial') &&
                <div className="w-full">
                  <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Observaciones <span className="text-xs text-amber-600 dark:text-amber-400">(opcional)</span></label>
                  <textarea name="observation" className="custom-style-input"
                    rows="2"
                    autoComplete="off"
                    maxLength={50}
                    value={changeState.observation}
                    onChange={e => setChangeState({ ...changeState, observation: e.target.value })}
                  />
                </div>
              }
            </div>
            <div className="w-full relative mt-4">
              <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Link</label>
              <input name="link" className="custom-style-input"
                autoComplete="off"
                required={true}
                value={changeState.link}
                type="text"
                onChange={e => setChangeState({ ...changeState, link: e.target.value })}
              />
            </div>
            <div className="w-full mt-5">
              <Button
                type="submit"
                style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                size='large' variant="contained" color={`primary`}
                disabled={isMutation.uloading ? true : false}
              >
                {isMutation.uloading ? <Spinner /> : <IoSave size={24} className="mr-2" />}
                {isMutation.uloading ? 'Procesando...' : `Actualizar`}
              </Button>
            </div>
          </form>
        </div>
      </Modal>

      <Modal
        open={reportorder.open}
        onClose={() => setReportOrder({
          ...reportorder,
          _id: "",
          open: false,
          message: "",
          orderId: "",
          nameplataform: "",
          plataformlogo: ""
        })}
        headerTitle={`Chat de ${reportorder.ventaId}`}
      >
        <div className="h-[calc(100vh-150px)] relative m-auto w-full">
          <div className="grid grid-wrap w-full md:w-4/12 mb-5">
            <div className="flex inline-flex items-center relative">
              <LazyLoadImage
                class="w-14 h-14 z-30 rounded-full flex inline-flex object-cover"
                alt={`No image ${reportorder.nameplataform}`}
                effect="opacity"
                src={`/images/logos/${reportorder.plataformlogo}`}
              />
              <div className="grid self-center">
                <div className="ml-2 text-base font-semibold text-brand-900 dark:text-brand-200 capitalize">{reportorder.nameplataform}</div>
              </div>
            </div>
          </div>

          <div className="h-[calc(100vh-300px)] overflow-y-auto flex flex-col space-y-4 p-4 bg-brand-100 dark:bg-brand-900">
            {reportorder.chat.map((msg, index) => (
              <div className="relative">
                <div
                  key={index}
                  className={`relative group flex ${msg?.user === admin?.id ? 'justify-end' : 'justify-start'
                    }`}
                >
                  {
                    msg?.user === admin?.id &&
                    <div className="w-5 h-5 mr-4">
                      <Dropdown
                        closeOnChildren={true}
                        button={<div className="opacity-0 group-hover:opacity-100 scale-0 group-hover:scale-100 hover:bg-lightPrimary/20 active:bg-lightPrimary/30 dark:hover:bg-darkPrimary/20 dark:active:bg-darkPrimary/30 transition-all ease-in-out duration-500 rounded-full p-2"><FaEllipsisVertical className="w-4 h-4 text-right cursor-pointer text-brand-900 dark:text-brand-100" /></div>}
                        children={
                          <div className="flex w-32 flex-col justify-start bg-white bg-cover bg-no-repeat shadow-lg shadow-brand-300 dark:!bg-brand-950 text-brand-900 dark:text-white dark:shadow-black">
                            <div className="flex flex-col">
                              <div onClick={() => handleDeleteMessage(msg._id)} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                Eliminar
                              </div>
                              <div onClick={() => setReportOrder({ ...reportorder, editmessageid: msg._id, editmessage: msg.message })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                Editar
                              </div>
                            </div>
                          </div>
                        }
                        animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
                        classNames={"!absolute right-4 top-9"}
                      />
                    </div>
                  }
                  <div
                    className={`max-w-xs relative p-4 rounded-lg ${msg?.user === admin?.id
                      ? 'bg-[#00008b] text-white'
                      : 'bg-brand-300 text-black'
                      }`}
                  >
                    {msg._id === reportorder.editmessageid ? (
                      <textarea
                        className="custom-style-input max-h-[90px] min-h-[55px]"
                        rows="2"
                        autoComplete="off"
                        maxLength={255}
                        placeholder="Escribe tu mensaje..."
                        onBlur={() => handleEditMessage(msg._id)}
                        type="text"
                        value={reportorder.editmessage}
                        onChange={(e) => setReportOrder({ ...reportorder, editmessage: e.target.value })}
                      />
                    ) : (
                      <p>{msg.message}</p>
                    )}
                    <span className={`text-xs ${msg?.user === admin?.id
                      ? 'text-brand-500'
                      : 'text-brand-700'
                      }`}>
                      {moment(msg.create_at).fromNow()}
                    </span>
                  </div>
                  {
                    msg?.user !== admin?.id &&
                    <div className="w-5 h-5 ml-1">
                      <Dropdown
                        closeOnChildren={true}
                        button={<div className="opacity-0 group-hover:opacity-100 scale-0 group-hover:scale-100 hover:bg-lightPrimary/20 active:bg-lightPrimary/30 dark:hover:bg-darkPrimary/20 dark:active:bg-darkPrimary/30 transition-all ease-in-out duration-500 rounded-full p-2"><FaEllipsisVertical className="w-4 h-4 text-right cursor-pointer text-brand-900 dark:text-brand-100" /></div>}
                        children={
                          <div className="flex w-32 flex-col justify-start bg-white bg-cover bg-no-repeat shadow-lg shadow-brand-300 dark:!bg-brand-950 text-brand-900 dark:text-white dark:shadow-black">
                            <div className="flex flex-col">
                              <div onClick={() => handleDeleteMessage(msg._id)} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                Eliminar
                              </div>
                              <div onClick={() => setReportOrder({ ...reportorder, editmessageid: msg._id, editmessage: msg.message })} className="text-sm font-medium transition duration-150 ease-out hover:ease-in cursor-pointer hover:bg-brand-100 dark:hover:bg-brand-800 px-3 py-2">
                                Editar
                              </div>
                            </div>
                          </div>
                        }
                        animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
                        classNames={"!absolute right-4 top-9"}
                      />
                    </div>
                  }
                </div>
              </div>
            ))}
            {
              reportorder.chat.at(-1)?.user === admin?.id &&
              <div className="flex items-center mt-5">
                <div className="mr-2 animate-pulse text-brand-900 dark:text-brand-100">esperando respuesta ...</div> <Spinner />
              </div>
            }
            <div ref={messagesEndRef} />
          </div>

          <div className="absolute w-full bottom-0 left-0 flex items-center gap-2">
            <input
              className="custom-style-input"
              value={reportorder.message}
              maxLength={255}
              onChange={(e) => setReportOrder({ ...reportorder, message: e.target.value })}
              placeholder="Escribe tu mensaje..."
            />
            <Button onClick={handleSubmitReportOrder} disabled={(isMutation.uloading || reportorder.message.trim().length < 1) ? true : false} variant="contained" sx={{ maxWidth: 65, minWidth: 65, maxHeight: 50, minHeight: 50 }} className="rounded-xl !bg-blue-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-blue-600 active:!bg-blue-700 dark:!bg-blue-400 dark:!text-white dark:hover:!bg-blue-300 dark:active:!bg-blue-200">
              <IoSend className="w-5 h-5 text-white" />
            </Button>
          </div>
        </div>
      </Modal>

      <div className="relative w-full">
        {!isMutation.loading ? ventasByPage.length > 0 ?
          <div className="flex flex-wrap mt-[260px] md:mt-[260px] lg:mt-[260px]">
            {ventasByPage.map((order) => {
              var sublogo = ""
              var nameplataform = ""
              var servicename = ""

              sublogo = order.plataform?.sublogo
              nameplataform = order.plataform?.name
              servicename = order.service?.name

              return (
                <div className={`p-4 relative w-full shadow-xl shadow-neutral-200 dark:shadow-none cursor-pointer md:w-12/12 bg-white dark:bg-brand-900 text-brand-900 dark:text-brand-200 flex flex-wrap mb-14 rounded-tr-lg rounded-br-lg rounded-bl-lg animation`} key={order._id}>
                  <label className={`absolute -top-10 left-0 p-2 flex items-center text-brand-800 dark:text-brand-200 text-base rounded-tl-lg rounded-tr-lg bg-white dark:bg-brand-900`}>
                    <span className="text-[12px]">{order.ventaId}</span>
                    {
                      order.orderId &&
                      <span className={`text-[12px] text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-1 ml-3`}>{order.orderId}</span>
                    }

                  </label>
                  <div className="flex flex-wrap items-center w-full md:w-full lg:w-[25%]">
                    <div className="flex inline-flex items-center">
                      <LazyLoadImage
                        className="object-cover max-w-[45px] min-w-[45px] max-h-[45px] min-h-[45px] z-10 rounded-full flex inline-flex"
                        alt={`No image ${nameplataform}`}
                        effect="opacity"
                        src={`/images/logos/${sublogo}`}
                      />
                      <div className="grid gap-1 self-center">
                        <div className="ml-2 text-sm capitalize font-semibold">{nameplataform}</div>
                        <div className="ml-2 text-xs truncate ...">{servicename}</div>
                        <div className="ml-2 flex items-center text-xs truncate ..."><MdAlternateEmail size={16} className='mr-[5px]' />  {order.user?.email}
                          <span className="ml-3">
                            <Tooltip title="copiar" arrow={true}>
                              <div>
                                <CopyToClipboard text={order.user.email} onCopy={() => onCopy('correo copiado')}>
                                  <FaClipboard size={20} className="text-brand-900 dark:text-brand-200 cursor-pointer w-[35px]" />
                                </CopyToClipboard>
                              </div>
                            </Tooltip>
                          </span>
                        </div>
                        <div className="ml-2 flex items-center text-xs truncate ..."><FaRegUser size={16} className='mr-[5px]' /> {order.user?.name}</div>
                        <div className="ml-2 flex items-center text-xs mt-1 truncate ..."><FaRegCalendarPlus size={16} className='mr-[5px]' />{setUnixToTimeFormat('created_at', order.created_at)}</div>
                      </div>
                    </div>
                  </div>

                  <div className="relative grid md:grid lg:flex grid-cols-2 md:grid-cols-3 lg:grid-cols-1 gap-3 items-center w-full md:w-full lg:w-[calc(75%-150px)] mt-5 md:mt-4 lg:mt-0">

                    <div className="w-full md:w-full lg:w-[8.27%] grid">
                      <div className="flex items-center justify-center uppercase text-sm">link</div>
                      <div className="flex items-center justify-center">
                        <div className="flex inline-flex items-center">
                          <div class="text-center text-indigo-950 dark:text-indigo-50 bg-gradient-to-r from-indigo-400 dark:from-indigo-500 rounded-l-lg flex items-center p-2">
                            <a href={`${order.link}`} target="_blank" className=""><IoOpenOutline size={24} /></a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-full lg:w-[14.27%] grid">
                      <div className="flex items-center justify-center uppercase text-sm">cantidad</div>
                      <div className="flex items-center justify-center">
                        <div className="flex inline-flex items-center">
                          <div class="text-center text-blue-950 dark:text-blue-50 bg-gradient-to-r from-blue-400 dark:from-blue-500 rounded-l-lg flex items-center p-2">
                            <MdAddReaction size={24} className="mr-2" />{order.quantity}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-full lg:w-[14.27%] grid">
                      <div className="flex items-center justify-center uppercase text-sm">Costo total</div>
                      <div className="flex items-center justify-center">
                        <div className="flex inline-flex items-center">
                          {
                            order.user?.reseller ?
                              <div class="text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2">
                                <FaCoins size={24} className="mr-2" />{currencyFormat(order.totalamount)}
                              </div>
                              :
                              <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2">
                                <FaUserTag size={24} className="mr-2" />{currencyFormat(order.totalamount)}
                              </div>
                          }
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-full lg:w-[14.27%] grid">
                      <div className="flex items-center justify-center uppercase text-sm">Inicio/Final</div>
                      <div className="flex items-center justify-center">
                        <div className="flex inline-flex items-center">
                          <div class="text-center text-cyan-950 dark:text-cyan-50 bg-gradient-to-r from-cyan-400 dark:from-cyan-500 rounded-l-lg flex items-center p-2">
                            <MdMovieFilter size={24} className="mr-2" />{order.start_count + ' / ' + (order.start_count + order.quantity)}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-full lg:w-[14.27%] grid">
                      <div className="flex items-center justify-center uppercase text-sm">progreso</div>
                      <div className="flex items-center justify-center">
                        <div className="flex inline-flex items-center">
                          <Box position="relative" display="inline-flex">
                            <CircularProgress
                              variant="determinate"
                              value={(100 / (order.quantity / (order.quantity - order.remains)))}
                              color={`${((100 / (order.quantity / (order.quantity - order.remains))) >= 0 && (100 / (order.quantity / (order.quantity - order.remains))) < 50) ? 'success' : ((100 / (order.quantity / (order.quantity - order.remains))) >= 50 && (100 / (order.quantity / (order.quantity - order.remains))) < 100) ? 'warning' : 'error'}`}
                              size={60}
                              thickness={6}
                            />
                            <Box
                              top={0}
                              left={0}
                              bottom={0}
                              right={0}
                              position="absolute"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <span className="text-sm font-bold">{`${((100 / (order.quantity / (order.quantity - order.remains)))).toFixed(0)}%`}</span>
                            </Box>
                          </Box>
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-full lg:w-[14.27%] grid">
                      <div className="flex items-center justify-center uppercase text-sm">faltan</div>
                      <div className="flex items-center justify-center">
                        <div className="flex inline-flex items-center">
                          <div class="text-center text-blue-950 dark:text-blue-50 bg-gradient-to-r from-blue-400 dark:from-blue-500 rounded-l-lg flex items-center p-2">
                            <FaHourglassStart size={24} className="mr-2" />{order.remains}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full md:w-full lg:w-[20.27%] grid">
                      <div className="flex items-center justify-center uppercase text-sm">estado</div>
                      <div className="flex items-center justify-center">
                        <div className="flex inline-flex items-center">
                          {order.state === "Completed" && <div class="text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2"><FaSquareCheck size={24} className="mr-2" />Completado</div>}
                          {order.state === "Partial" && <div class="text-center text-brand-950 dark:text-brand-50 bg-gradient-to-r from-brand-400 dark:from-brand-500 rounded-l-lg flex items-center p-2"><FaHandHoldingDollar size={24} className="mr-2" />R. Parcial</div>}
                          {order.state === "Checking" && <div class="text-center text-brand-950 dark:text-brand-50 bg-gradient-to-r from-brand-400 dark:from-brand-500 rounded-l-lg flex items-center p-2"><FaGavel size={24} className="mr-2" />En revisión</div>}
                          {(order.state === "Processing" || order.state === "In progress") && <div class="text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2"><FaBusinessTime size={24} className="mr-2" />Procesando</div>}
                          {order.state === "Pending" && <div class="text-center text-yellow-950 dark:text-yellow-50 bg-gradient-to-r from-yellow-400 dark:from-yellow-500 rounded-l-lg flex items-center p-2"><FaShippingFast size={24} className="mr-2" />Pendiente</div>}
                          {order.state === "Rejected" && <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><IoBan size={24} className="mr-2" />Rechazado</div>}
                          {order.state === "Refunded" && <div class="text-center text-cyan-950 dark:text-cyan-50 bg-gradient-to-r from-cyan-400 dark:from-cyan-500 rounded-l-lg flex items-center p-2"><FaHandHoldingDollar size={24} className="mr-2" />Reembolsado</div>}
                          {order.state === "Canceled" && <div class="text-center text-red-950 dark:text-red-50 bg-gradient-to-r from-red-400 dark:from-red-500 rounded-l-lg flex items-center p-2"><IoCloseCircle size={24} className="mr-2" />Cancelado</div>}
                        </div>
                      </div>
                      <div className="text-xs text-center">ult. vez {moment(order.last_update).fromNow()}</div>
                      <label className="text-xs w-full text-center">{order.observation}</label>
                    </div>
                  </div>

                  <div className="flex items-center gap-2 justify-end w-full md:w-full lg:md:w-[150px] mt-5 md:mt-5 lg:mt-0">

                    <Tooltip title="editar" arrow={true}>
                      <Button onClick={() => setChangeState({
                        ...changeState,
                        open: true,
                        _id: order._id,
                        ventaId: order.ventaId,
                        amount: order.totalamount,
                        link: order.link,
                        newState: order.state,
                        orderId: order.orderId
                      })} disabled={(order.state === "Completed" || order.state === "Refunded" || order.state === "Partial" || order.state === "Canceled") ? true : false} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35, position: "relative" }} className="rounded-xl !bg-amber-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-amber-600 active:!bg-amber-700 dark:!bg-amber-400 dark:!text-white dark:hover:!bg-amber-300 dark:active:!bg-amber-200">
                        <FaPencilAlt className="w-5 h-5 text-white" />
                      </Button>
                    </Tooltip>

                    <Tooltip title="emitir pedido" arrow={true}>
                      <Button onClick={() => handleEmitirPedido(order._id)} disabled={(order.state !== "Pending" || isMutation.uloading) ? true : false} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35, position: "relative" }} className="rounded-xl !bg-green-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-green-600 active:!bg-green-700 dark:!bg-green-400 dark:!text-white dark:hover:!bg-green-300 dark:active:!bg-green-200">
                        {
                          isMutation.uloading ?
                            <SpinnerButtom />
                            :
                            <MdScheduleSend className="w-5 h-5 text-white" />
                        }
                      </Button>
                    </Tooltip>

                    <Tooltip title="chat" arrow={true}>
                      <Button onClick={() => handleReportOrder(order._id, order.orderId, nameplataform, sublogo, order.ventaId, order.chat)} disabled={(order.state === "Completed" || order.state === "Refunded" || order.state === "Partial" || order.state === "Canceled") ? true : false} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35, position: "relative" }} className="rounded-xl !bg-blue-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-blue-600 active:!bg-blue-700 dark:!bg-blue-400 dark:!text-white dark:hover:!bg-blue-300 dark:active:!bg-blue-200">
                        <FaCommentDots className="w-5 h-5 text-white" />
                        {
                          order.chat.length > 0 &&
                          <div className="absolute z-10 flex items-center justify-center font-semibold max-w-[30px] min-w-[30px] max-h-[30px] min-h-[30px] -top-4 -right-4 p-2 rounded-full bg-red-500 text-white text-sm">{order.chat.length}</div>
                        }
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              )
            })}
          </div> : <div className="alert-danger font-semibold mt-[260px] md:mt-[260px] lg:mt-[260px]">Sin resultados en la búsqueda! o no existen pedidos registradas</div> : <div className="mt-[260px] md:mt-[260px] lg:mt-[260px]"><SpinnerData>Cargando pedidos, por favor espere...</SpinnerData></div>}
      </div>
    </Wrapper>
  );
};

export default SocialOrders;
