import { applyTransparency, convertirsegundos, currencyFormat, generateBackgroundColor, message, sensurarEmail, sensurarString } from "../../utils/Funciones.js";
import { BsCash, BsHandbag, BsTagFill } from "react-icons/bs";
import { Button, Checkbox, FormControl, IconButton, MenuItem, Select, TextareaAutosize, Tooltip } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import Spinner from "../Spinner";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import moment from 'moment';
import 'moment/locale/es';
import { useNavigate } from "react-router-dom";
import draftToHtml from "draftjs-to-html";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import parse from 'html-react-parser';
import { useDispatch, useSelector } from "react-redux";
import Modal from "../modal/Modal";
import { uriSerialized } from '../../utils/uriSerialized';
import { SET_TO_DEFAULT_RESPONSE, UPDATE_LAST10_OF_PLATAFORM, createorder, getServiceApiById } from "../../store/features/slices/socialSlice";
import { SET_CASH_USER } from "../../store/features/slices/userSlice";
import { SET_TO_DEFAULT_RESPONSE as SET_TO_DEFAULT_RESPONSE_SEARCH } from "../../store/features/slices/searchSlice";
import Socket, { authSocket, logoutSocket } from '../../utils/Socket';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaUser } from "react-icons/fa6";
import CardVip from "../CardVip.jsx";
import { RiShoppingBag4Fill, RiVipCrownFill } from "react-icons/ri";
import { useTranslation } from 'react-i18next';

const CategoriesSocial = ({ socket, comprarVip }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [purchaseSocialService, SetPurchaseSocialService] = useState(
    {
      _id: "",
      open: false,
      plataformId: "",
      preciofijo: 0,
      porcentajeaplicado: 0,
      speedseconds: 0,
      policy: "",
      logo: "",
      name: "",
      acept_terms: false,
      services: null,
      serviceId: "",
      servicetype: "",
      comments: "",
      link: "",
      urlexamplephone: "",
      urlexampledesktop: "",
      discountvip: 0,
      quantity: 0
    }
  )
  const { userToken, user, userInfo } = useSelector((state) => state.userReducer);
  const { isMutation, plataforms, services, serviceAPI } = useSelector((state) => state.socialReducer);
  const { isMutation: isMutationSearch } = useSelector((state) => state.searchReducer);

  const [service, setServiceId] = useState("")
  const [usdtToPen, setUsdtToPen] = useState(3.80);

  //const { data: serviceId, isFetching: loadingservice, refetch } = useGetServiceByIdQuery({ serviceId: service });
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  var policyData = useMemo(
    () => draftToHtml(convertToRaw(editorState.getCurrentContent())),
    [editorState]
  );

  useEffect(() => {
    const fetchExchangeRate = async () => {
      try {
        const response = await fetch('https://api.exchangerate-api.com/v4/latest/USD');
        const data = await response.json();
        setUsdtToPen(data.rates.PEN);
      } catch (error) {
        console.error('Error fetching exchange rate:', error);
      }
    };
    const interval = setInterval(fetchExchangeRate, 10000); // Fetch every 5 seconds
    fetchExchangeRate();
    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  const handleOpenPurchaseSocialService = (plataform) => {
    SetPurchaseSocialService({ ...purchaseSocialService, open: true, plataformId: plataform._id, logo: plataform.sublogo, name: plataform.name })
  }

  useEffect(() => {
    if (isMutation.success) {
      message(isMutation.message, 'success', 5)
      dispatch(SET_CASH_USER({ type: 'DECREASE', amount: isMutation.extra.amount }))
      Socket.emit('uHistoryPlataform', { newHistory: isMutation.extra.newHistory })
      dispatch(UPDATE_LAST10_OF_PLATAFORM(isMutation.extra.newHistory))
      dispatch(SET_TO_DEFAULT_RESPONSE())
      navigate('/manage-account/usersocialorders');
    }
  }, [dispatch, isMutation.success])

  const handleChangeService = (event) => {
    if (userToken) {
      !isMutation.loading && services.find((service) => {
        if (Number(service.serviceId) === Number(event.target.value)) {
          SetPurchaseSocialService({
            ...purchaseSocialService,
            _id: service._id,
            plataformId: service.plataform?._id,
            logo: service.plataform?.sublogo,
            name: service.plataform?.name,
            preciofijo: userInfo?.reseller ? Number(service.preciofijoreseller) : Number(service.preciofijouser),
            porcentajeaplicado: Number(service.porcentajeadicional),
            speedseconds: Number(service.speedseconds),
            quantity: 0,
            link: "",
            comments: "",
            discountvip: service.discountvip,
            open: true,
            acept_terms: false,
            servicetype: service.servicetype,
            urlexampledesktop: service.urlexampledesktop,
            urlexamplephone: service.urlexamplephone
          })
          const rawContent = service.policy;
          const contentState = convertFromRaw(JSON.parse(rawContent));
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState)
        }
      })
      setServiceId(event.target.value)
    } else {
      navigate('/login');
    }
  };

  useEffect(() => {
    if (service) {
      const queryOptions = {
        serviceId: service
      }
      const query = queryOptions ? "?" + uriSerialized(queryOptions) : ""
      dispatch(getServiceApiById(query))
    }
  }, [service]);

  useEffect(() => {
    if (isMutationSearch.open) {
      if (isMutationSearch.data?.type === 'Social') {
        handleChangeService({ target: { value: isMutationSearch.data?.serviceId } })
        dispatch(SET_TO_DEFAULT_RESPONSE_SEARCH())
      }
    }
  }, [isMutationSearch.open])

  const validarURL = (miurl) => {
    try {
      new URL(miurl);
      return true;
    } catch (err) {
      return false;
    }
  }

  const handleSubmitNewOrderServiceSocial = e => {
    e.preventDefault()
    if (userToken) {
      var qty = Number(purchaseSocialService.quantity)
      var min = serviceAPI.min
      var max = serviceAPI.max
      var refill = serviceAPI.refill
      var cancel = serviceAPI.cancel
      var serviceid = Number(service)
      var constototal = Number((purchaseSocialService.quantity / 1000) * ((serviceAPI.rate * usdtToPen) * ((purchaseSocialService.porcentajeaplicado * 0.01) + 1)) + purchaseSocialService.preciofijo - (userInfo?.vip?.state === 'ACTIVE' && new Date(userInfo?.vip?.expirationdate) > new Date() ? purchaseSocialService.discountvip : 0))
      var link = purchaseSocialService.link
      var Idplataform = purchaseSocialService.plataformId
      var comments = purchaseSocialService.comments
      var servicetype = purchaseSocialService.servicetype


      if (qty % 10 === 0) {
        if (Number(qty) >= min && Number(qty) <= max) {
          if (serviceid !== "") {
            if (link !== "") {
              if (validarURL(link)) {
                dispatch(createorder({
                  _id: purchaseSocialService._id,
                  userId: user?.id,
                  plataformId: Idplataform,
                  qty: qty,
                  serviceId: serviceid,
                  constototal: constototal,
                  link: link,
                  usdtToPen: usdtToPen,
                  refill: refill,
                  cancel: cancel,
                  comments: comments,
                  servicetype: servicetype,
                  actionTypeAdmin: false
                }))
              } else {
                message(`ERROR: Link inválido`, 'error', 3)
              }
            } else {
              message(`ERROR: Link está vacio`, 'error', 3)
            }
          } else {
            message(`ERROR: Id de servicio vacio`, 'error', 3)
          }
        } else {
          message(`La cantidad mínima/máxima es ${min + '/' + max}`, 'error', 3)
        }
      } else {
        message(`la cantidad debe ser múltiplo de 10, ejemplo : 10, 20, ... 200, 500, ... 1000, 2000, etc`, 'error', 6)
      }
    } else {
      navigate('/login');
    }
  }

  const handleClosePurchaseSocialService = () => {
    setServiceId("")
    SetPurchaseSocialService({
      ...purchaseSocialService,
      open: !purchaseSocialService.open,
      plataformId: "",
      preciofijo: 0,
      porcentajeaplicado: 0,
      speedseconds: 0,
      policy: "",
      logo: "",
      name: "",
      acept_terms: false,
      services: null,
      discountvip: 0,
      serviceId: "",
      servicetype: "",
      link: "",
      urlexamplephone: "",
      urlexampledesktop: "",
      quantity: 0
    })
  }

  var settings = {
    centerMode: true,
    infinite: true,
    centerPadding: "8px",
    slidesToShow: 3,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    nextArrow: <></>,
    prevArrow: <></>
  };

  var settings2 = {
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    nextArrow: <></>,
    prevArrow: <></>
  };


  return (
    <>
      <Modal
        open={purchaseSocialService.open}
        onClose={handleClosePurchaseSocialService}
        headerTitle={`${purchaseSocialService.name}`}
        size="normal"
      >
        <div className="relative m-auto w-full">
          <div className="w-full md:w-12/12">
            <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">{t('availableservices')}</label>
            <div className="relative">
              <FormControl fullWidth>
                <Select
                  required
                  name="service"
                  className="flex items-center"
                  variant="outlined"
                  value={service}
                  onChange={handleChangeService}
                >
                  {
                    !isMutation.loading && services.filter(fil => fil.enabled && fil.plataform?._id === purchaseSocialService.plataformId).map((service) => {
                      return <MenuItem value={service.serviceId} key={service._id}><LazyLoadImage width={25} height={25} src={`/images/logos/${purchaseSocialService.logo}`} className="mr-2 rounded-full" />{service.name}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </div>
          </div>

          {!isMutation.aloading ? service &&
            <form onSubmit={handleSubmitNewOrderServiceSocial}>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 mt-5">

                <div className="w-full md:w-12/12 flex flex-wrap gap-4 mt-5">
                  <div className="w-full relative">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">{t('socialinfotobuy')} ...</label>
                    <input autoFocus name="link" className="custom-style-input"
                      autoComplete="off"
                      required
                      value={purchaseSocialService.link}
                      type="text"
                      onChange={(e) => SetPurchaseSocialService({ ...purchaseSocialService, link: e.target.value })}
                    />
                  </div>

                  <div className="w-full relative">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">{t('quantity')}{purchaseSocialService.servicetype === 'customcomments' && ' de comentarios'}</label>
                    <input name="quantity" className="custom-style-input"
                      autoComplete="off"
                      required
                      disabled={purchaseSocialService.servicetype !== 'default' ? true : false}
                      value={purchaseSocialService.quantity}
                      type="number"
                      onChange={(e) => SetPurchaseSocialService({ ...purchaseSocialService, quantity: e.target.value })}
                      min={serviceAPI?.min}
                      max={serviceAPI?.max}
                    />
                    <p class="mt-2 text-xs text-red-600 dark:text-red-500">{purchaseSocialService.servicetype !== 'default' ? '' : 'Cantidad debe ser múltiplo de 10 (ejemplo: 10, 20, 30, 40, ...)'}</p>
                  </div>
                  {
                    purchaseSocialService.servicetype === 'customcomments' &&
                    <div className="w-full md:w-12/12 p-3">
                      <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">{t('howputcomentaries')}</label>
                      <div className="relative">
                        <FormControl fullWidth>
                          <TextareaAutosize
                            required={purchaseSocialService.servicetype === 'customcomments' ? true : false}
                            name="comments"
                            minRows={10}
                            className="bg-brand-50 p-[15px] text-brand-900 dark:text-brand-200 text-base rounded-lg focus:ring-blue-600 block w-full dark:bg-brand-950 shadow-md shadow-brand-500 dark:shadow-black placeholder-brand-300 dark:placeholder-brand-600 dark:focus:ring-blue-600 focus:outline-none focus:ring-2"
                            variant="outlined"
                            value={purchaseSocialService.comments}
                            onChange={(e) => SetPurchaseSocialService({ ...purchaseSocialService, comments: e.target.value, quantity: e.target.value.split('\n').filter(linea => linea.trim() !== '').length })}
                          />
                        </FormControl>
                      </div>
                    </div>
                  }

                  <div className="w-auto relative">
                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">{t('price')}</label>
                    <div className="text-4xl max-w-full md:max-w-full lg:max-w-[450px]">
                      {userInfo?.reseller ?
                        <div class="flex items-center text-center text-amber-950 dark:text-amber-50 bg-gradient-to-r from-amber-400 dark:from-amber-500 rounded-l-lg flex items-center p-2">
                          {currencyFormat(purchaseSocialService.quantity > 0 ? ((purchaseSocialService.quantity / 1000) * ((serviceAPI?.rate * usdtToPen) * ((purchaseSocialService.porcentajeaplicado * 0.01) + 1)) + purchaseSocialService.preciofijo - (userInfo?.vip?.state === 'ACTIVE' && new Date(userInfo?.vip?.expirationdate) > new Date() ? purchaseSocialService.discountvip : 0)) : 0)}
                          {
                            (userInfo?.vip?.state === 'ACTIVE' && new Date(userInfo?.vip?.expirationdate) > new Date()) ?
                              <div className="grid justify-center ml-7">
                                <CardVip vip={userInfo?.vip} />
                                <div className="text-xs text-center">{t('saved')} {currencyFormat(purchaseSocialService.discountvip)}</div>
                              </div>
                              :
                              <div className="grid justify-center ml-7">
                                <div className="relative bg-gradient-to-t from-lightPrimary/40 dark:from-darkPrimary/40 to-lightPrimary dark:to-darkPrimary inline-block text-transparent bg-clip-text text-2xl font-bold">
                                  CON VIP
                                  <RiVipCrownFill className="absolute right-7 -top-3 text-lightPrimary dark:text-darkPrimary rotate-45" />
                                </div>
                                <div className="text-xs text-center">Ahorras {currencyFormat(purchaseSocialService.discountvip)}
                                  <label onClick={() => (comprarVip(), handleClosePurchaseSocialService())} className="ml-4 text-[12px] text-red-400 dark:text-red-500 cursor-pointer">comprar VIP</label>
                                </div>
                              </div>
                          }
                        </div>
                        :
                        <div class="flex items-center text-center text-green-950 dark:text-green-50 bg-gradient-to-r from-green-400 dark:from-green-500 rounded-l-lg flex items-center p-2">
                          {currencyFormat(purchaseSocialService.quantity > 0 ? ((purchaseSocialService.quantity / 1000) * ((serviceAPI?.rate * usdtToPen) * ((purchaseSocialService.porcentajeaplicado * 0.01) + 1)) + purchaseSocialService.preciofijo) - (userInfo?.vip?.state === 'ACTIVE' && new Date(userInfo?.vip?.expirationdate) > new Date() ? purchaseSocialService.discountvip : 0) : 0)}
                          {
                            (userInfo?.vip?.state === 'ACTIVE' && new Date(userInfo?.vip?.expirationdate) > new Date()) ?
                              <div className="grid justify-center ml-7">
                                <CardVip vip={userInfo?.vip} />
                                <div className="text-xs text-center">{t('saved')} {currencyFormat(purchaseSocialService.discountvip)}</div>
                              </div>
                              :
                              <div className="grid justify-center ml-7">
                                <div className="relative bg-gradient-to-t from-lightPrimary/40 dark:from-darkPrimary/40 to-lightPrimary dark:to-darkPrimary inline-block text-transparent bg-clip-text text-2xl font-bold">
                                {t('whitvip')}
                                  <RiVipCrownFill className="absolute right-9 -top-3 text-lightPrimary dark:text-darkPrimary rotate-45" />
                                </div>
                                <div className="text-xs text-center flex items-center">{t('saved')} {currencyFormat(purchaseSocialService.discountvip)}
                                  <span onClick={() => (comprarVip(), handleClosePurchaseSocialService())} className="ml-4 flex items-center text-[12px] text-white dark:text-white cursor-pointer p-2 bg-lightError dark:bg-darkError rounded-lg">
                                    <RiVipCrownFill className="mr-2 text-lg" /> {t('iwant')}
                                  </span>
                                </div>
                              </div>
                          }
                        </div>
                      }
                    </div>
                  </div>

                  <div className="w-full relative">
                    <label className="block mb-2 text-sm font-medium text-brand-900 dark:text-white">{t('termsaccept')}</label>
                    <div class="flex items-center">
                      <Checkbox
                        required
                        checked={purchaseSocialService.acept_terms ? true : false}
                        onClick={() => SetPurchaseSocialService({ ...purchaseSocialService, acept_terms: !purchaseSocialService.acept_terms })}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#1d4ed8' } }}
                      />
                      {!purchaseSocialService.acept_terms && <span class="animate-ping absolute inline-flex h-8 w-8 rounded-full bg-blue-500 left-5"></span>}
                      <label for="link-checkbox" class="ml-1 text-sm font-medium text-brand-900 dark:text-brand-300">{t('acceptdescription')}.</label>

                    </div>
                  </div>


                  <div className="w-full relative">
                    <Button
                      disabled={(isMutation.vloading || !purchaseSocialService.acept_terms) ? true : false}
                      sx={{
                        "&.Mui-disabled": {
                          background: "#525252",
                          color: "#a3a3a3"
                        }
                      }}
                      type="submit" style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                      size='large' variant="contained" color="primary" startIcon={isMutation.vloading ? <Spinner /> : <BsCash size={24} className="mr-2" />}>
                      {isMutation.vloading ? 'Procesando...' : 'PAGAR'}</Button>
                  </div>
                </div>
                <div>
                  <div className="mt-7 grid grid-cols-1 md:grid-cols-2 gap-4 w-full md:w-12/12 p-3 bg-brand-200 dark:bg-brand-900 text-xs text-brand-900 dark:text-brand-200">
                    <div className="bg-brand-50 dark:bg-brand-950 p-3 rounded">
                      <div className="w-full text-red-600 dark:text-red-500 font-bold">{t('qtyminmax')}</div>
                      <div className="w-full mt-3">{serviceAPI?.min} / {serviceAPI?.max}</div>
                    </div>

                    <div className="bg-brand-50 dark:bg-brand-950 p-3 rounded">
                      <div className="w-full text-red-600 dark:text-red-500 font-bold">{t('category')}</div>
                      <div className="w-full mt-3">{serviceAPI?.category}</div>
                    </div>

                    <div className="bg-brand-50 dark:bg-brand-950 p-3 rounded">
                      <div className="w-full text-red-600 dark:text-red-500 font-bold">{t('description')}</div>
                      <div className="w-full mt-3">{parse(policyData)}</div>
                    </div>

                    <div className="bg-brand-50 dark:bg-brand-950 p-3 rounded">
                      <div className="w-full text-red-600 dark:text-red-500 font-bold">{t('aproxtime')}</div>
                      <div className="w-full mt-3">{convertirsegundos(purchaseSocialService.speedseconds)}</div>
                    </div>

                  </div>

                  <div className="mt-3 grid grid-cols-1 md:grid-cols-1 gap-4 w-full md:w-12/12 p-3 bg-brand-200 dark:bg-brand-900 text-xs text-brand-900 dark:text-brand-200">
                    <div className="bg-brand-50 dark:bg-brand-950 p-3 rounded">
                      <div className="w-full text-yellow-600 dark:text-yellow-500 font-bold">{t('linkexamplephone')}</div>
                      <div className="w-full mt-3">{purchaseSocialService.urlexamplephone}</div>
                    </div>

                    <div className="bg-brand-50 dark:bg-brand-950 p-3 rounded">
                      <div className="w-full text-yellow-600 dark:text-yellow-500 font-bold">{t('linkexampledesktop')}</div>
                      <div className="w-full mt-3">{purchaseSocialService.urlexampledesktop}</div>
                    </div>
                  </div>
                </div>

              </div>
            </form>
            :
            <div className="mt-5 flex items-center justify-center text-brand-900 dark:text-brand-200"><Spinner />{t('loadingserviceinfo')}</div>
          }
        </div>
      </Modal>

      <div style={{ fontFamily: 'Outfit, sans-serif' }} className="w-full 
       text-brand-900 dark:text-brand-100
        mb-3 mt-4 md:mt0 text-center text-lg md:text-xl pt-5 pb-5 md:pt-14 md:pb-14 contrast-[125%]">
        <div style={{ fontFamily: 'Outfit, sans-serif' }} className="uppercase">{t('services')}</div>
        <div className="text-4xl md:text-6xl bg-gradient-to-t from-brand-800 to-cyan-500 inline-block text-transparent bg-clip-text">
          MARKETING DIGITAL
        </div>
      </div>
      <div className="flex flex-wrap w-full pb-10">
        <div className="w-full min-h-[22rem] grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-x-3 md:gap-x-8 gap-y-8 md:gap-y-14 mb-4">
          {
            !isMutation.loading && plataforms.filter(fil => fil.enabled).map((plataform, index) => {

              return (
                <div onClick={() => handleOpenPurchaseSocialService(plataform)} key={index} className="relative shadow-lg shadow-brand-300 dark:shadow-black bg-white dark:bg-brand-950 group w-full min-h-[300px] h-[300px] md:h-[310px] lg:h-[320px] overflow-hidden rounded-xl transition-transform ease-in-out duration-700 md:hover:scale-[115%] cursor-pointer">
                  <div style={{ backgroundColor: `${applyTransparency(plataform.themecolor, 0.2)}` }} class="absolute w-56 h-48 blur-[90px] -left-1/2 -top-1/2"></div>
                  <div style={{ backgroundColor: `${applyTransparency(plataform.themecolor, 0.2)}` }} class="absolute w-56 h-48 blur-[90px] -right-1/2 -top-1/2"></div>
                  <div style={{ backgroundColor: `${plataform.themecolor}` }} class="absolute w-72 h-48 blur-[50px] -right-1/2 -bottom-1/2"></div>
                  <div className="absolute z-30 right-2 top-2 opacity-0 transform translate-y-10 group-hover:opacity-100 group-hover:translate-y-0 transition duration-500 ease-in-out">
                    <LazyLoadImage
                      className="object-cover max-w-[65px] min-w-[65px] max-h-[65px] min-h-[65px] rounded-full z-10 transition-all ease-in-out duration-700 [box-shadow:0px_1px_5px_1px_var(--tw-shadow-color)] shadow-black dark:shadow-black"
                      alt={`No image ${plataform.name}`}
                      effect="opacity"
                      src={`/images/logos/${plataform.sublogo}`}
                    />
                  </div>
                  <LazyLoadImage

                    className="h-[calc(100%-6.5rem)] w-[calc(100%-2px)] z-5 absolute ml-[1px]"
                    alt={`No image ${plataform.name}`}
                    effect="opacity"
                    src={`/images/logos/${plataform.logo}`}
                  />
                  <div className="absolute h-full w-full top-0">
                    <div class="relative w-full h-[calc(100%-9rem)] z-10">
                    </div>
                    <div className="h-[9rem]">
                      <div style={{ backgroundColor: `${applyTransparency(plataform.themecolor, 0.1)}` }} className="relative">
                        <div class="relative w-full h-[0rem] z-10 flex items-center justify-center">
                        </div>
                        <div class="relative w-full h-[4rem] z-10 flex items-center justify-center text-brand-50 text-[0.9rem] md:text-[1rem] lg:text-[1.1rem] uppercase font-semibold [text-shadow:0px_3px_3px_var(--tw-shadow-color)] shadow-black dark:shadow-black">
                          {plataform.name}
                        </div>
                      </div>
                      <div class="relative w-full h-[4.2rem] z-10 flex items-center justify-center bg-white dark:bg-brand-950">
                        {
                          plataform.history.length >= 3 &&
                          <div className="flex flex-wrap justify-center w-full">
                            <Slider className="!relative overflow-hidden !rounded-l-full !rounded-r-lg max-w-[95px] min-w-[95px] max-h-[2.5rem] min-h-[2.5rem] z-20" key={index} {...settings}>
                              {
                                plataform.history?.map((item) => {
                                  return (
                                    <>
                                      {
                                        item?.history?.user?.photo === 'unknowphoto.webp' ?
                                          <div style={{ background: `${generateBackgroundColor(item?.history?.user?.name)}` }} className="uppercase -ml-[8px] relative mr-4 size-[2.5rem] rounded-full flex items-center justify-center text-white text-xl font-normal border-2 border-white dark:border-brand-900 z-10" key={item._id}>
                                            {item?.history?.user?.name.substr(0, 1)}
                                          </div>
                                          :
                                          <div className="-ml-[8px] overflow-hidden z-10 relative mr-4 size-[2.5rem] rounded-full flex items-center justify-center" key={item._id}>
                                            <LazyLoadImage
                                              className="object-cover size-[2.5rem] rounded-full border-2 border-white dark:border-brand-900 z-10"
                                              alt={`No image ${item?.history?.user?.name}`}
                                              effect="opacity"
                                              src={`/images/photos/${item?.history?.user?.photo}`}
                                            />
                                          </div>
                                      }
                                    </>
                                  )
                                })
                              }
                            </Slider>
                            {
                              plataform.history?.length > 0 &&
                              <div className="-ml-[17px] z-30 relative size-[2.5rem] rounded-full border-2 border-white dark:border-brand-900 z-10 flex items-center justify-center bg-brand-900 dark:bg-brand-50">
                                <FaUser size={22} className="text-brand-50 dark:text-brand-900" />
                              </div>
                            }

                            <Slider className="w-[calc(95%)] z-20 !m-auto h-[20px]" key={plataform._id} {...settings2}>
                              {
                                plataform.history?.map((item) => {
                                  return (
                                    <div className="relative text-[9px] font-normal text-center text-black dark:text-white px-1 overflow-hidden" key={item._id}>
                                      {sensurarEmail(item?.history?.user?.email ? item?.history?.user?.email : 'unknow@unknow.com')} {moment(item?.history?.created_at).fromNow()}
                                    </div>)
                                })
                              }
                            </Slider>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </>
  )
};

export default CategoriesSocial;
